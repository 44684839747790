import { Button, Icon, Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { api } from '../../../../../../../../../services'
import actions from '../../../../../../../../../store/actions'
import nanoid from 'nanoid'

const BulletPoints = ({
  onCancel,
  extractedText,
  lessonTitle,
  pageStructureData,
  lessonUpdate,
  currentPage
}) => {
  const [isGenerating, setIsGenerating] = useState(false)

  const [text, setText] = useState('')

  const [bulletPoints, setBulletPoints] = useState('')

  const { handleLessonInput } = actions.lessonBuilder

  const courseTitle = useSelector(state => state.courseBuilder.courseTitle)

  const dispatch = useDispatch()

  useEffect(() => {
    setText(extractedText)
  }, [extractedText])

  const startGeneration = () => {
    setIsGenerating(true)

    api.courses
      .generateIntroOrSummary({
        content: extractedText,
        isIntroduction: false,
        isSummary: true,
        course_name: courseTitle,
        topic: lessonTitle ? lessonTitle : 'Bullet Points'
      })
      .then(res => {
        if (res.data.body) {
          setBulletPoints(res.data.body)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setIsGenerating(false)
      })
  }

  function formatTextToHTML(text) {
    return text
      .split('\n')
      .map(line => {
        let cleanedLine = line.replace(/^[-\s]+/, '').trim() // Remove leading '-' and spaces
        if (cleanedLine === '') return '<p><br></p>'
        return `<ul><li><strong> ${cleanedLine}</strong></li></ul>`
      })
      .join('\n')
  }

  const addToCurrentPage = () => {
    const newPreviewData = [...pageStructureData]

    const currentPageData = newPreviewData[currentPage - 1]

    const pageHasText = currentPageData.data.some(
      item => item.type === 'TEXT' && item.display === 'CONTENT'
    )

    const formattedText = formatTextToHTML(bulletPoints)

    if (pageHasText) {
      const item = newPreviewData[currentPage - 1].data.find(
        item => item.type === 'TEXT' && item.display === 'CONTENT'
      )

      if (item) {
        item.params.isBulletPoints = true
        item.params.content = formattedText
        item.params.originalText = extractedText
      }
    } else {
      newPreviewData[currentPage - 1].data.push({
        uid: nanoid(8),
        type: 'TEXT',
        display: 'CONTENT',
        params: {
          isBulletPoints: true,
          content: formattedText,
          x: 57,
          y: 146,
          width: 900,
          height: 353,
          originalText: extractedText
        }
      })
    }

    dispatch(handleLessonInput('previewData', newPreviewData))
    lessonUpdate({ previewData: newPreviewData })

    onCancel()
  }

  return (
    <Modal visible title="Bullet Points" footer={null} onCancel={onCancel}>
      {bulletPoints ? (
        <>
          <Input.TextArea
            autoSize={{ minRows: 8, maxRows: 20 }}
            value={bulletPoints}
            onChange={e => setBulletPoints(e.target.value)}
          />
          <div className="btn-group" style={{ marginTop: '20px' }}>
            <Button
              type="primary"
              shape="round"
              icon="check"
              onClick={() => addToCurrentPage()}
            >
              Add To Current Page
            </Button>
          </div>
        </>
      ) : (
        <>
          <Input.TextArea
            autoSize={{ minRows: 8, maxRows: 20 }}
            value={text}
            onChange={e => setText(e.target.value)}
          />

          <div className="btn-group" style={{ marginTop: '20px' }}>
            <Button
              type="primary"
              shape="round"
              onClick={() => startGeneration()}
              loading={isGenerating}
            >
              Generate Bullet Points{' '}
              {!isGenerating && <Icon type="arrow-right" />}
            </Button>
          </div>
        </>
      )}
    </Modal>
  )
}

export default BulletPoints
