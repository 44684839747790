import { useState } from 'react'
import { message } from 'antd'

export const useCharacterCreation = (
  existingCharacters,
  setExistingCharacters,
  onSuccess
) => {
  const [characterName, setCharacterName] = useState('')
  const [characterParams, setCharacterParams] = useState({
    gender: '',
    age: '',
    appearance: '',
    attire: '',
    height: '',
    eyeColor: '',
    hairColor: '',
    skinTone: '',
    race: ''
  })
  const [generatedCharacterTags, setGeneratedCharacterTags] = useState([])

  const handleParamChange = (param, value) => {
    setCharacterParams(prevState => ({
      ...prevState,
      [param]: value
    }))

    const newTags = generateCharacterTags(param, value)
    setGeneratedCharacterTags(prev => {
      const filteredTags = prev.filter(tag => tag.param !== param)
      return [...filteredTags, ...newTags]
    })
  }

  const resetCharacterCreation = () => {
    setCharacterName('')
    setCharacterParams({
      gender: '',
      age: '',
      appearance: '',
      attire: '',
      height: '',
      eyeColor: '',
      hairColor: '',
      skinTone: '',
      race: ''
    })
    setGeneratedCharacterTags([])
  }

  return {
    characterName,
    setCharacterName,
    characterParams,
    handleParamChange,
    generatedCharacterTags,
    resetCharacterCreation
  }
}

const generateCharacterTags = (param, value) => {
  const tagMappings = {
    gender: { color: 'purple' },
    age: { color: 'blue' },
    appearance: { color: 'green' },
    attire: { color: 'orange' },
    height: { color: 'magenta' },
    eyeColor: { color: 'cyan' },
    hairColor: { color: 'geekblue' },
    skinTone: { color: 'volcano' },
    race: { color: 'lime' }
  }

  return tagMappings[param]
    ? [
        {
          label: value,
          color: tagMappings[param].color,
          param: param
        }
      ]
    : []
}

export const capitaliseFirstLetter = str =>
  str.charAt(0).toUpperCase() + str.slice(1)
