import { Button, Card, Checkbox, Form, Input, Modal, message } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'

import './AiQuiz.scss'
import { api } from '../../../../../../services'

const questionTypes = {
  'multiple-choice': 'Multiple Choice',
  'true-false': 'True/False',
  'open-ended': 'Open Ended'
}

const AiQuizForm = props => {
  const [isGenerating, setIsGenerating] = useState(false)
  const [selectedQuizType, setSelectedQuizType] = useState(null)

  const { form, onClose, previewData, onQuizData } = props
  const { getFieldDecorator, validateFieldsAndScroll, setFieldsValue } = form

  useEffect(() => {
    setFieldsValue({ question_type: selectedQuizType })
  }, [selectedQuizType])

  const extractedText = useMemo(() => extractSelectedPageText(), [previewData])

  const handleSubmit = e => {
    e && e.preventDefault()
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        setIsGenerating(true)

        const payload = {
          params: {
            course_content: extractedText,
            quiz_type: values.question_type,
            num_questions: values.number_of_questions
          }
        }

        api.quizes
          .generateAiQuiz(payload)
          .then(res => {
            if (res.data) {
              const temp = res.data
              const data = temp && temp.body
              const parsedData = JSON.parse(data)
              const transformedData = transformData(parsedData)
              onQuizData({
                data: transformedData,
                ...values
              })
            }
          })
          .catch(err => {
            message.error(err.message)
          })
          .finally(() => {
            setIsGenerating(false)
          })
      }
    })
  }

  function transformData(data) {
    switch (selectedQuizType) {
      case 'multiple-choice':
        return transformMCQData(data)
      case 'true-false':
        return transformTrueFalseQuestion(data)
      case 'open-ended':
        return transformOpenEndedQuestions(data)
      default:
        return []
    }
  }

  function transformMCQData(data) {
    function transformQuestion(question) {
      const transformedQuestion = {
        '0': question.choices[0],
        '1': question.choices[1],
        '2': question.choices[2],
        '3': question.choices[3],
        uid: generateUid(),
        type: 'multiple',
        title: question.question,
        point: 10,
        answer_type: 'multiple',
        answers: []
      }

      const correctChoiceIndex =
        question.correct_choice.charCodeAt(0) - 'a'.charCodeAt(0)

      question.choices.forEach((choice, index) => {
        transformedQuestion.answers.push({
          uid: generateUid(),
          answer: choice,
          correct: index === correctChoiceIndex
        })
      })

      return transformedQuestion
    }

    return data.map(transformQuestion)
  }

  function transformTrueFalseQuestion(data) {
    return data.map(question => {
      const transformedQuestion = {
        '0': 'TRUE',
        '1': 'FALSE',
        uid: generateUid(),
        type: 'single',
        title: question.question,
        point: 10,
        answer_type: 'single',
        answers: []
      }

      const correctAnswer = question.answer.toUpperCase() === 'TRUE'

      transformedQuestion.answers.push({
        uid: generateUid(),
        answer: 'TRUE',
        correct: correctAnswer
      })

      transformedQuestion.answers.push({
        uid: generateUid(),
        answer: 'FALSE',
        correct: !correctAnswer
      })

      return transformedQuestion
    })
  }

  function transformOpenEndedQuestions(data) {
    return data.map(question => ({
      uid: generateUid(),
      type: 'free',
      title: question.question,
      point: 10,
      answer_type: 'free',
      free: question.answer
    }))
  }

  function generateUid() {
    return Math.random()
      .toString(36)
      .substring(2, 14)
  }

  function extractSelectedPageText() {
    const selectedPages = previewData.filter(page => page.pageSelected)

    let concatenatedText = ''

    selectedPages.forEach(page => {
      page.data.forEach(element => {
        if (element.type === 'TEXT' && element.display !== 'HEADING') {
          const textContent =
            new DOMParser().parseFromString(element.params.content, 'text/html')
              .body.textContent || ''
          concatenatedText += textContent + ' '
        }
      })
    })

    return concatenatedText
      .replace(/I could not find content related to this topic/g, '') // Remove unwanted phrase
      .replace(/\s+/g, ' ') // Normalize extra spaces
      .trim()
  }

  function validateNumberOfQuestions(rule, value, callback) {
    if (!value) {
      callback('Please enter number of questions')
    } else if (value < 1 || value > 10) {
      callback('Please enter number of questions between 1 and 10')
    } else {
      callback()
    }
  }

  return (
    <Modal
      title="Generate Quiz"
      visible
      footer={null}
      onCancel={onClose}
      centered
      className="quiz-generate"
    >
      <Form
        className="quiz-generate-form"
        layout="vertical"
        onSubmit={handleSubmit}
      >
        {/* <Form.Item colon={false} label="Quiz Name">
          {getFieldDecorator('quiz_name', {
            rules: [{ required: true, message: 'Please input quiz name!' }]
          })(<Input type="text" />)}
        </Form.Item> */}

        <Form.Item colon={false} label="Select Question Type" required={false}>
          {getFieldDecorator('question_type', {
            rules: [{ required: true, message: 'Please select question type!' }]
          })(
            <div className="question-type-container">
              {Object.keys(questionTypes).map(key => (
                <div
                  className={`question-type-choice ${
                    selectedQuizType === key ? 'selected' : ''
                  }`}
                  key={key}
                  onClick={() => setSelectedQuizType(key)}
                >
                  {questionTypes[key]}
                </div>
              ))}
            </div>
          )}
        </Form.Item>

        <div className="quiz-no-of-questions">
          <h3>Number of Questions to be generated</h3>
          <Form.Item colon={false} className="quiz-no-of-questions-input">
            {/* number of questions */}
            {getFieldDecorator('number_of_questions', {
              rules: [
                {
                  validator: validateNumberOfQuestions
                }
              ]
            })(<Input type="number" min={1} max={10} />)}
          </Form.Item>
        </div>

        {/* 
        <Form.Item colon={false} label="Attempts Count">
          {getFieldDecorator('attempts_count', {
            rules: [{ required: true, message: 'Please input attempts count!' }]
          })(<Input type="number" />)}
        </Form.Item>


        <Form.Item colon={false}>
          {getFieldDecorator('is_restricted', {
            rules: [{ required: false }]
          })(<Checkbox>Disable Skip Quiz</Checkbox>)}
        </Form.Item> */}

        <Form.Item colon={false} className="question-type__btn">
          <Button
            size="large"
            shape="round"
            type="primary"
            htmlType="submit"
            loading={isGenerating}
          >
            Generate Quiz
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Form.create()(AiQuizForm)
