export const attributes = [
  {
    label: 'Gender',
    name: 'gender',
    options: ['male', 'female']
  },
  {
    label: 'Age',
    name: 'age',
    options: [
      'infant',
      'child',
      'teenager',
      'young adult',
      'middle-aged',
      'elderly'
    ]
  },
  {
    label: 'Appearance',
    name: 'appearance',
    options: [
      'freckled',
      'scarred',
      'tattooed',
      'plain',
      'distinguished',
      'wrinkled',
      'blemished'
    ]
  },
  {
    label: 'Attire',
    name: 'attire',
    options: [
      'casual',
      'formal',
      'sportswear',
      'traditional attire',
      'uniform',
      'costume',
      'vintage'
    ]
  },
  {
    label: 'Height',
    name: 'height',
    options: ['very short', 'short', 'average height', 'tall', 'very tall']
  },
  {
    label: 'Eye Color',
    name: 'eyeColor',
    options: ['blue', 'green', 'brown', 'gray', 'hazel', 'amber', 'violet']
  },
  {
    label: 'Hair Color',
    name: 'hairColor',
    options: [
      'blonde',
      'brown',
      'black',
      'red',
      'gray',
      'white',
      'unnatural (pink, blue, etc.)'
    ]
  },
  {
    label: 'Hair Style',
    name: 'hairStyle',
    options: [
      'buzz cut',
      'bob cut',
      'braided',
      'ponytail',
      'curly',
      'straight',
      'wavy',
      'bald'
    ]
  },
  {
    label: 'Skin Tone',
    name: 'skinTone',
    options: ['light', 'fair', 'medium', 'olive', 'tan', 'brown', 'dark']
  },
  {
    label: 'Race',
    name: 'race',
    options: [
      'indian',
      'african',
      'caucasian',
      'asian',
      'hispanic',
      'native american',
      'mixed heritage'
    ]
  }
]

export const existingCharacters = [
  {
    id: 1,
    name: 'Warrior Princess',
    image: 'https://via.placeholder.com/300x400',
    tags: ['strong', 'leader', 'medieval'],
    characterType: 'predefined',
    prompt:
      'A brave warrior princess in medieval armor, standing confidently on a battlefield.'
  },
  {
    id: 2,
    name: 'Cyber Hacker',
    image: 'https://via.placeholder.com/300x400',
    tags: ['tech', 'urban', 'mysterious'],
    characterType: 'predefined',
    prompt:
      'A skilled hacker in a neon-lit cyberpunk city, working on advanced technology.'
  }
]
