import React, { Component, Fragment } from 'react'
import { Rnd } from 'react-rnd'
import { Button, Icon, Popover, Tooltip } from 'antd'
import _ from 'lodash'
import { isSafari } from 'react-device-detect'
import { Builder } from '../../../components'
import { builder, general } from '../../../constants'
import './Element.scss'
import { customContentStyle } from './Element.ctrl'
import {
  Delete,
  Edit,
  whiteSoundIcon
} from '../../../views/Courses/Create/nestedComponents/PageBuilder/Components/Audio/images'
import { api } from '../../../services'
import { quizDelete } from '../../../services/api/quizes'

const { types } = builder

export default class extends Component {
  constructor() {
    super()

    this.handleOutsideClick = this.handleOutsideClick.bind(this)

    this.state = {
      isModal: false,
      isEditor: false
    }
  }

  /**
   * auto activate editor function depend on droppend file uid
   */
  componentDidMount() {
    const { node, droppedFile } = this.props
    if (droppedFile) {
      _.each(droppedFile, file => {
        if (file.uid === node.uid) {
          this.handleEdit(null, node)
        }
      })
    }
  }

  getAudioStatusById(id) {
    return api.library.getMediaInfoById(id).then(data => {
      if (data) {
        return data.data.is_synthesized_audio
      }
    })
  }

  openEditor = () => {
    const { setEditElement } = this.props
    this.setEditor(true)
    setEditElement(true) // use to set redux isEditElement to use in Page.jsx
  }

  closeEditor = () => {
    const { setEditElement } = this.props
    this.setEditor(false)
    setEditElement(false)
  }

  setEditor = value => {
    this.setState({
      isEditor: value
    })
  }

  /**
   * this condition use to check when we need to add eventlistener on handleOutsideClick
   */
  /* handleEdit = (event, { type }) => {
    if (event) event.stopPropagation()

    if (type === general.TEXT) {
      document.addEventListener('click', this.handleOutsideClick, false)
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }
    this.openEditor()
  } */

  handleEdit = (event, node) => {
    const { showEditAudioModal, showEditAudioDetailModal } = this.props
    if (event) event.stopPropagation()
    if (node && node.type === general.AUDIO) {
      const {
        node: {
          params: { content_id }
        }
      } = this.props
      if (content_id) {
        this.getAudioStatusById(content_id).then(data => {
          // Todo : backend should send is_synthesized_audio along with content_id
          !data
            ? showEditAudioDetailModal({
                contentId: content_id,
                isShowDetailEditAudio: true
              })
            : showEditAudioModal({
                contentId: content_id,
                isShowEditAudio: true
              })
        })
      }
    }

    if (node && node.type === general.TEXT) {
      const {
        showEditTextModal,
        node: { uid },
        node: {
          params: { content }
        }
      } = this.props
      showEditTextModal({
        uid: uid,
        content: content,
        isShowEditText: true
      })
    }

    if (node && node.type === general.IMAGE) {
      const {
        showEditImageModal,
        node: { uid },
        node: {
          params: { content_id }
        }
      } = this.props
      // if (content_id) {
      //   showEditImageModal({
      //     contentId: content_id,
      //     isShowEditImage: true
      //   })
      // } else {
      showEditImageModal({
        uid: uid,
        isShowEditImage: false
      })
      // }
    }

    if (
      node &&
      (node.type === general.VIDEO || node.type === general.VIDEO_STREAM)
    ) {
      const {
        showInsertVideoModal,
        showEditVideoModal,
        node: {
          uid,
          params: { content_id }
        }
      } = this.props

      if (content_id) {
        showEditVideoModal({
          contentId: content_id,
          isShowEditVideo: true
        })
      } else {
        showInsertVideoModal({
          isShowInsertVideoModal: true,
          uid: uid
        })
      }
    }

    if (node && node.type === general.QUIZ) {
      const {
        showEditQuizModal,
        node: { uid },
        node: {
          params: { content, isSimulation = false }
        }
      } = this.props
      showEditQuizModal({
        uid: uid,
        contentId: content,
        isSimulation: isSimulation,
        isShowEditQuiz: true
      })
    }

    if (node && node.type === general.ANNOTATION) {
      const {
        showAnnotationModal,
        node: { uid },
        node: {
          params: { content }
        }
      } = this.props
      showAnnotationModal({
        uid: uid,
        contentId: content,
        isShowAnnotation: true
      })
    }

    if (node && node.type === general.SCENARIO) {
      const {
        showScenarioModal,
        node: {
          params: { content }
        }
      } = this.props

      showScenarioModal({
        content: content,
        isShowScenarioModal: true
      })
    }
  }

  /**
   *
   * this function use to check is Click on current focus content or outside
   */
  handleOutsideClick(e) {
    if (!this.node) {
      return
    }
    const isCurrentContent = this.node.contains(e.target)
    if (!isCurrentContent) {
      document.removeEventListener('click', this.handleOutsideClick, false)
      this.closeEditor()
    }
  }

  onResizeStart = uid => {
    const { _refs } = this.props
    const elementRef = _refs.get(uid)

    elementRef.classList.add('__transform')
  }

  onResizeStop = (ref, node) => {
    const { uid } = node
    const { _refs, handleParams } = this.props
    const elementRef = _refs.get(uid)

    elementRef.classList.remove('__transform')

    const width = Number(ref.style.width.replace('px', ''))
    const height = Number(ref.style.height.replace('px', ''))

    if (handleParams) {
      handleParams(uid, {
        width,
        height,
        ...(node.display && node.display === 'LOGO' && { display: 'LOGO' })
      })
    }
  }

  onDrag = uid => {
    const { _refs } = this.props
    const elementRef = _refs.get(uid)
    elementRef.classList.add('__transform')
    this.bringToTop(uid)
  }

  onDragStop = (d, node) => {
    const { uid } = node
    const { _refs, handleParams } = this.props
    const elementRef = _refs.get(uid)
    elementRef.classList.remove('__transform')

    const x = d.x
    const y = d.y

    if (handleParams) {
      handleParams(uid, {
        x,
        y,
        ...(node.display && node.display === 'LOGO' && { display: 'LOGO' })
      })
    }
  }

  onClickRemoveElement = (event, node) => {
    event.stopPropagation()

    if (node) {
      const {
        uid,
        type,
        params: { content: quizId }
      } = node

      if (type === general.QUIZ) {
        quizDelete(quizId)
      }

      const { handleData, pageStructure } = this.props
      const data = pageStructure.filter(node => node.uid !== uid)

      if (handleData) {
        handleData(data)
      }
    }
  }

  /**
   * on Drag or click bring the current box element like (image, text, video...) to the top layer index
   * uid is the id of the element that dragged or clicked
   */
  bringToTop = uid => {
    const { pageStructure, handleData } = this.props
    const element = pageStructure.find(element => element.uid === uid)
    const data = pageStructure.filter(element => element.uid !== uid)
    data.push(element)
    if (handleData) {
      handleData(data)
    }
  }

  /**
   * this functions is use to handle ref for outSideClick function and setRef for click on any content bring to the top
   */
  handleRef = (n, node, setRef) => {
    setRef(node.uid, n)
    this.node = n
  }

  checkY = param => {
    const { isLandScape } = this.props
    var elementY = param.y + param.height
    if (elementY > 552 && isLandScape) {
      return Math.floor(Math.random() * 16) + 5
    } else {
      return param.y
    }
  }

  checkWidth = ({ params }) => {
    if (params.width > 768) {
      return 768
    }

    if (params.width >= 640 && params.x >= 100) {
      return 768
    }

    if (params.width >= 700 && params.width <= 710) {
      return 768
    }
    return params.width
  }

  checkX = ({ x, width }) => {
    if (width >= 640 && x >= 100) {
      return 0
    }
    if (width > 768) {
      return 0
    }
    return x
  }

  getIcon = ({ params }) => {
    if (!params.chat) {
      return 'wechat'
    }
    return 'delete'
  }

  calculateScaledCoordinates(x, y) {
    // Original box dimensions
    const originalWidth = 1022.78
    const originalHeight = 551.77

    // New box dimensions
    const newWidth = 208
    const newHeight = 113

    // Calculate scaled coordinates
    const scaledX = (x / originalWidth) * newWidth
    const scaledY = (y / originalHeight) * newHeight

    return {
      x: scaledX,
      y: scaledY
    }
  }

  handleGenerateAudio = (event, node) => {
    event.stopPropagation()
    const {
      node: {
        params: { content, isBulletPoints = false, originalText = '' }
      },
      showCreateAudioModal
    } = this.props

    // Use DOMParser to parse HTML content
    const parser = new DOMParser()
    const doc = parser.parseFromString(
      isBulletPoints ? originalText : content,
      'text/html'
    )

    // Get text content without HTML tags
    const plainText = doc.body.textContent || doc.body.innerText

    if (plainText) {
      showCreateAudioModal({
        content: plainText.trim(), // trim to remove extra whitespace
        isShowGenerateAudioModal: true
      })
    }
  }

  render() {
    const {
      node,
      handleParams,
      setRef,
      upload,
      handleMultipleDrop,
      elIndex,
      node: {
        params: { content }
      },
      isLandScape,
      isAutoBackground,
      isPreview
    } = this.props

    const { isEditor } = this.state

    let match = ''
    if (content) {
      if (isNaN(content)) {
        let string = content.match(/images.pexels.com/g)
        if (string) {
          match = string.toString()
        }
      } else {
        match = content.toString()
      }
    }

    console.log({
      color: types.BACKGROUND_COLOR,
      type: node.type,
      match: node.type === types.BACKGROUND_COLOR
    })

    if (
      node.type === types.BACKGROUND_IMAGE ||
      node.type === types.BACKGROUND_COLOR ||
      node.type === types.BACKGROUND_IMAGE_AUTO
    ) {
      return <Builder.Preview node={node} isAutoBackground={isAutoBackground} />
    }

    const ca = this.calculateScaledCoordinates(node.params.x, node.params.y)

    return (
      <Fragment>
        <Rnd
          bounds="parent"
          disableDragging={isEditor}
          enableUserSelectHack={false}
          // lockAspectRatio={true}
          default={{
            x: isPreview ? ca.x / 0.4 : node.params.x,
            y: isPreview ? ca.y / 0.4 : this.checkY(node.params),
            width: node.params.width,
            height: node.params.height
          }}
          enableResizing={{
            bottomRight: true
          }}
          minWidth={node.type === 'AUDIO' ? (isSafari ? 243 : 143) : 30}
          minHeight={node.type === 'AUDIO' ? (isSafari ? 99 : 46) : 30}
          onResizeStart={() => this.onResizeStart(node.uid)}
          onResizeStop={(e, dir, ref) => this.onResizeStop(ref, node)}
          onDrag={() => this.onDrag(node.uid)}
          onDragStop={(e, d) => this.onDragStop(d, node)}
          style={customContentStyle}
          key={`${node.params.x}-${node.params.y}-${node.params.width}-${node.params.height}`}
        >
          <div
            className="el"
            ref={n => {
              this.handleRef(n, node, setRef)
            }}
            onClick={() => this.bringToTop(node.uid)}
            onDoubleClick={e => (!isLandScape ? this.handleEdit(e, node) : '')}
          >
            {!isEditor && (
              <div
                className="el__controls"
                onDoubleClick={e =>
                  !isLandScape ? this.handleEdit(e, node) : ''
                }
              >
                {node.type === types.IMAGE &&
                  node.display &&
                  node.display === 'LOGO' && (
                    <div className="el__control">
                      <Tooltip
                        title="This is a logo element. For this logo, resizing or
                              moving it will adjust its position and size across
                              all pages in the lesson"
                      >
                        <Icon type="info" className="el__control__info" />
                      </Tooltip>
                    </div>
                  )}

                {node.type === types.TEXT && node.params.content && (
                  <div className="el__control">
                    <Button onClick={e => this.handleGenerateAudio(e, node)}>
                      <img src={whiteSoundIcon} alt="" />
                    </Button>
                  </div>
                )}
                {!isLandScape && (
                  <div className="el__control">
                    {(match !== 'images.pexels.com' ||
                      node.type === types.TEXT) && (
                      <Button onClick={e => this.handleEdit(e, node)}>
                        <img src={Edit} alt="" />
                      </Button>
                    )}
                  </div>
                )}
                <div className="el__control">
                  <Button onClick={e => this.onClickRemoveElement(e, node)}>
                    <img src={Delete} alt="" />
                  </Button>
                </div>
              </div>
            )}

            <div className="el__content">
              {isEditor ? (
                <div className="el__editor">
                  {node.type === types.TEXT && (
                    <Builder.Elements.Text
                      content={node.params.content}
                      node={node}
                      uid={node.uid}
                      handleParams={handleParams}
                      closeEditor={this.closeEditor}
                    />
                  )}
                  {node.type === types.IMAGE && (
                    <Builder.Elements.Image
                      node={node}
                      uid={node.uid}
                      handleParams={handleParams}
                      handleMultipleDrop={handleMultipleDrop}
                      closeEditor={this.closeEditor}
                      upload={upload}
                      elIndex={elIndex}
                      isPreview={isPreview}
                    />
                  )}
                  {node.type === types.VIDEO && (
                    <Builder.Elements.Video
                      node={node}
                      uid={node.uid}
                      handleParams={handleParams}
                      handleMultipleDrop={handleMultipleDrop}
                      closeEditor={this.closeEditor}
                      upload={upload}
                      elIndex={elIndex}
                    />
                  )}
                  {node.type === types.VIDEO_STREAM && (
                    <Builder.Elements.Video
                      node={node}
                      uid={node.uid}
                      handleParams={handleParams}
                      handleMultipleDrop={handleMultipleDrop}
                      closeEditor={this.closeEditor}
                      upload={upload}
                      elIndex={elIndex}
                    />
                  )}
                  {node.type === types.AUDIO && (
                    <Builder.Elements.Audio
                      node={node}
                      uid={node.uid}
                      handleParams={handleParams}
                      handleMultipleDrop={handleMultipleDrop}
                      closeEditor={this.closeEditor}
                      upload={upload}
                      elIndex={elIndex}
                    />
                  )}
                  {node.type === types.QUIZ && (
                    <Builder.Elements.EQuiz
                      content={node.params}
                      uid={node.uid}
                      handleParams={handleParams}
                      closeEditor={this.closeEditor}
                    />
                  )}
                  {node.type === types.ANNOTATION && (
                    <Builder.Elements.EAnnotation
                      content={node.params.content}
                      uid={node.uid}
                      handleParams={handleParams}
                      closeEditor={this.closeEditor}
                    />
                  )}

                  {node.type === types.SCENARIO && (
                    <Builder.Preview node={node} />
                  )}
                </div>
              ) : (
                <Builder.Preview node={node} />
              )}
            </div>
          </div>
        </Rnd>
      </Fragment>
    )
  }
}
