import React, { useState, useRef, useEffect } from 'react'
import {
  Card,
  Row,
  Col,
  Input,
  Button,
  message,
  Icon,
  Spin,
  Alert,
  Result,
  Modal
} from 'antd'
import {
  fetchVoices,
  generateVoiceover,
  defaultSettings,
  filterVoices,
  getLabelIcon,
  AudioManager
} from './voiceover.utils'
import VoiceSettings from './VoiceSettings'
import VoiceSelector from './VoiceSelector'
import './Voiceover.scss'
import StepTitle from '../StepTitle'

const { Search } = Input

const VoiceoverGenerator = props => {
  const { formData, setFormData, onValidationChange } = props

  const [text, setText] = useState(
    'This is the text generated from the previous step. You can edit it.'
  )
  const [voices, setVoices] = useState([])
  const [loadingVoices, setLoadingVoices] = useState(false)
  const [selectedVoice, setSelectedVoice] = useState(null)
  const [isSettingsVisible, setIsSettingsVisible] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [settings, setSettings] = useState(defaultSettings)
  const [searchQuery, setSearchQuery] = useState('')
  const [generatedAudio, setGeneratedAudio] = useState(null)

  const [playingVoiceId, setPlayingVoiceId] = useState(null)

  const audioManager = useRef(new AudioManager(setPlayingVoiceId))
  const audioRef = useRef(null)

  useEffect(() => {
    loadVoices()
  }, [])

  useEffect(() => {
    if (formData) {
      setText(formData.voiceoverText)

      if (formData.voiceoverAudio) {
        onAudioUrlGenerated(formData.voiceoverAudio)
      }
    }
  }, [formData])

  const loadVoices = async () => {
    try {
      setLoadingVoices(true)
      const voicesData = await fetchVoices()
      setVoices(voicesData)
    } catch (error) {
      message.error('Failed to fetch voices')
    } finally {
      setLoadingVoices(false)
    }
  }

  const handlePlaySample = (voiceId, previewUrl, e) => {
    e.stopPropagation()
    if (playingVoiceId === voiceId) {
      audioManager.current.stop()
    } else {
      audioManager.current.play(voiceId, previewUrl)
    }
  }

  const handleGenerateVoiceover = async () => {
    if (!selectedVoice) {
      message.warning({
        content: 'Please select a voice first',
        icon: <Icon type="info-circle" />
      })
      return
    }

    if (!text.trim()) {
      message.warning({
        content: 'Please enter some text for the voiceover',
        icon: <Icon type="info-circle" />
      })
      return
    }

    setIsGenerating(true)

    try {
      const audioUrl = await generateVoiceover(text, selectedVoice, settings)
      onAudioUrlGenerated(audioUrl)
      message.success({
        content: 'Voiceover generated successfully',
        icon: <Icon type="check-circle" />
      })
    } catch (error) {
      message.error({
        content: 'Failed to generate voiceover',
        icon: <Icon type="close-circle" />
      })
    } finally {
      setIsGenerating(false)
    }
  }

  const onAudioUrlGenerated = audioUrl => {
    setGeneratedAudio(audioUrl)
    if (audioRef.current) {
      audioRef.current.src = audioUrl
    }
  }

  const handleSettingsChange = (key, value) => {
    if (key === 'reset') {
      setSettings(defaultSettings)
    } else if (key === 'apply') {
      setIsSettingsVisible(false)
    } else {
      setSettings(prev => ({ ...prev, [key]: value }))
    }
  }

  const handleCreateNew = () => {
    Modal.confirm({
      centered: true,
      title: 'Do you want to create a new voiceover?',
      content: 'This will overwrite the current voiceover.',
      onOk: () => setGeneratedAudio(null),
      onCancel: () => console.log('Cancel')
    })
  }

  const filteredVoices = filterVoices(voices, searchQuery)

  const onAddVoice = () => {
    if (generatedAudio) {
      setFormData({
        ...props.formData,
        voiceoverText: text,
        voiceoverAudio: generatedAudio
      })
      onValidationChange(prev => ({ ...prev, voiceover: true }))
    }
  }

  return (
    <div className="voiceover-generator">
      <div className="header">
        <StepTitle
          iconType="sound"
          title="Generate Voiceover"
          description="Select voice settings and generate a voiceover."
        />

        {!generatedAudio && (
          <Button
            type="primary"
            size="large"
            onClick={handleGenerateVoiceover}
            disabled={!selectedVoice}
            loading={isGenerating}
            icon="robot"
          >
            {isGenerating ? 'Generating Voiceover...' : 'Generate Voiceover'}
          </Button>
        )}
      </div>

      <Input.TextArea
        autoSize={{ minRows: 3 }}
        value={text}
        onChange={e => setText(e.target.value)}
        className="text-input"
        placeholder="Enter text for voiceover generation..."
      />

      {!generatedAudio && (
        <>
          {!selectedVoice && (
            <div className="selection-hint">
              <Icon type="info-circle" /> Click on a voice to select and
              generate voiceover
            </div>
          )}

          <VoiceSelector
            loading={loadingVoices}
            voices={filteredVoices}
            selectedVoice={selectedVoice}
            onVoiceSelect={setSelectedVoice}
            onPlaySample={handlePlaySample}
            playingVoiceId={playingVoiceId}
            onSettingsClick={() => setIsSettingsVisible(true)}
          />
        </>
      )}

      {generatedAudio && (
        <Card>
          <Result
            status="success"
            title="Successfully generated voiceover audio."
            subTitle="You can preview or download the generated voiceover."
          />

          <div className="audio-player">
            <audio controls>
              <source src={generatedAudio} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>

          <div className="voiceover-actions">
            <Button
              type="primary"
              key="preview"
              size="large"
              onClick={onAddVoice}
            >
              Use as Voiceover
            </Button>
            <Button
              key="use"
              type="primary"
              ghost
              onClick={handleCreateNew}
              size="large"
            >
              Create New
            </Button>
          </div>
        </Card>
      )}

      <VoiceSettings
        visible={isSettingsVisible}
        onClose={() => setIsSettingsVisible(false)}
        settings={settings}
        onSettingsChange={handleSettingsChange}
      />
    </div>
  )
}

export default VoiceoverGenerator
