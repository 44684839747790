import React, { memo } from 'react'
import { Tag } from 'antd'
import './CharacterCard.scss'

const CharacterCard = memo(({ character, isSelected, onClick }) => (
  <div
    className={`character-card ${isSelected ? 'selected' : ''}`}
    onClick={() => onClick(character)}
  >
    <div
      className="character-image-container"
      style={{
        backgroundImage: character.image ? `url(${character.image})` : 'none'
      }}
    >
      {!character.image && <div className="fallback-image">No Image</div>}
    </div>
    <div className="character-details">
      <h4 className="character-name">{character.name}</h4>
      <div className="character-tags">
        {character.tags.map(tag => (
          <Tag key={tag} className="character-tag">
            {tag}
          </Tag>
        ))}
      </div>
      {character.characterType === 'custom' && <Tag color="blue">Custom</Tag>}
    </div>
    {isSelected && <div className="selected-overlay">Selected</div>}
  </div>
))

export default CharacterCard
