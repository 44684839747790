import React, { useState } from 'react'
import {
  Table,
  Button,
  Icon,
  Tooltip,
  Tag,
  Input,
  Dropdown,
  Menu,
  Row,
  Col
} from 'antd'

const VoiceSelector = ({
  loading,
  voices,
  selectedVoice,
  onVoiceSelect,
  onPlaySample,
  playingVoiceId,
  onSettingsClick
}) => {
  const [searchText, setSearchText] = useState('')

  const handleSearch = value => {
    setSearchText(value)
  }

  const filteredVoices = voices.filter(
    voice =>
      voice.name.toLowerCase().includes(searchText.toLowerCase()) ||
      Object.values(voice.labels || {}).some(label =>
        label.toLowerCase().includes(searchText.toLowerCase())
      )
  )

  const columns = [
    {
      title: 'Voice',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, record) => (
        <div className="voice-name">
          <span className="voice-title">{name}</span>
          {record.labels && record.labels.gender && (
            <span className="voice-gender">({record.labels.gender})</span>
          )}
        </div>
      )
    },
    {
      title: 'Labels',
      dataIndex: 'labels',
      key: 'labels',
      width: '30%',
      render: labels => {
        if (!labels) return null
        const relevantLabels = ['accent', 'age', 'use_case']
          .map(key => ({
            key,
            value: labels[key]
          }))
          .filter(label => label.value)

        return (
          <div className="voice-labels">
            {relevantLabels.map(({ key, value }) => (
              <Tooltip key={key} title={`${key}: ${value}`}>
                <Tag>{value}</Tag>
              </Tooltip>
            ))}
          </div>
        )
      }
    },
    {
      title: 'Preview',
      key: 'preview',
      width: '25%',
      render: (_, record) => (
        <Button
          ghost
          type="primary"
          size="small"
          onClick={e => {
            e.stopPropagation()
            onPlaySample(record.voice_id, record.preview_url, e)
          }}
        >
          <Icon
            type={playingVoiceId === record.voice_id ? 'pause' : 'caret-right'}
          />
          {playingVoiceId === record.voice_id ? 'Playing' : 'Play Sample'}
        </Button>
      )
    },
    {
      title: 'Action',
      key: 'action',
      width: '25%',
      render: (_, record) => (
        <div className="action-buttons">
          <Button
            size="small"
            onClick={e => {
              e.stopPropagation()
              onVoiceSelect(record)
            }}
          >
            <Icon
              type={
                selectedVoice && selectedVoice.voice_id === record.voice_id
                  ? 'check'
                  : 'select'
              }
            />
            {selectedVoice && selectedVoice.voice_id === record.voice_id
              ? 'Selected'
              : 'Select'}
          </Button>
        </div>
      )
    }
  ]

  const tableTitle = () => (
    <div className="search-section">
      <Row gutter={16} align="middle">
        <Col flex="auto" span={16}>
          <Input.Search
            prefix={<Icon type="search" />}
            placeholder="Search voices by name or label..."
            onChange={e => handleSearch(e.target.value)}
          />
        </Col>
        <Col span={8}>
          <Button
            ghost
            type="primary"
            style={{ width: '100%' }}
            onClick={() => onSettingsClick()}
            disabled={!selectedVoice}
          >
            <Icon type="setting" /> Voice Settings
          </Button>
        </Col>
      </Row>
    </div>
  )

  return (
    <Table
      loading={loading}
      dataSource={filteredVoices}
      columns={columns}
      rowKey="voice_id"
      pagination={false}
      rowClassName={record =>
        selectedVoice && record.voice_id === selectedVoice.voice_id
          ? 'selected-row'
          : ''
      }
      onRow={record => ({
        onClick: () => onVoiceSelect(record),
        className: 'clickable-row'
      })}
      size="small"
      className="voice-selector-table"
      title={tableTitle}
    />
  )
}

export default VoiceSelector
