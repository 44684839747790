import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Builder } from '../../../../../../../../components' // Adjust the import path as needed
import { Button, Icon, Popconfirm } from 'antd'
import StoryCreationForm from './StoryCreationForm'
import { useDispatch, useSelector } from 'react-redux'
import ScenarioHandler from './Scenario'
import * as lessonBuilderActions from '../../../../../../../../store/actions/lessonBuilder'

import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc'
import { AiQuiz } from '../../../../../../../../components/Builder/Elements/EQuiz/internal'
import { useTranslation } from 'react-i18next'

import DeleteIcon from '../../../../../../../../assets/bin.svg'
import IntroOrSummary from './IntroOrSummary'
import BulletPoints from './BulletPoints'

const PagePreviewSection = props => {
  const {
    lessonTitle,
    currentPage,
    onPreviewItemClick,
    toPage,
    activeType,
    handleType,
    scenarioId,
    courseId,
    lessonId
  } = props
  const containerRef = useRef(null);
  const isStory = activeType === 'story'
  const isScenario = activeType === 'scenario'
  const isQuizAI = activeType === 'quiz'
  const isIntroduction = activeType === 'introduction'
  const isSummary = activeType === 'summary'
  const isBulletPoints = activeType === 'bulletPoints'

  const isSelectable =
    isStory || isScenario || isQuizAI || isIntroduction || isSummary

  const [previewData, setPreviewData] = useState([])
  const [showStoryCreationForm, setShowStoryCreationForm] = useState(false)
  const [showScenario, setShowScenario] = useState(false)
  const [showQuizForm, setShowQuizForm] = useState(false)
  const [showIntroductionForm, setShowIntroductionForm] = useState(false)
  const [showSummaryForm, setShowSummaryForm] = useState(false)
  const [showBulletPointsForm, setShowBulletPointsForm] = useState(false)

  const pageBuilder = useSelector(state => state.pageBuilder)
  const pageStructureData = useSelector(
    state => state.lessonBuilder.pages_landscape
  )
  const dispatch = useDispatch()

  const { isShowScenarioModal, content } = pageBuilder
  const scenarioIndex = scenarioId
    ? scenarioId
    : isShowScenarioModal
      ? content
      : null

  useEffect(() => {
    if (isShowScenarioModal) {
      setShowScenario(true)
    }
  }, [isShowScenarioModal])

  useEffect(() => {
    setPreviewData(pageStructureData)
  }, [pageStructureData])

  useEffect(() => {
    if (!isStory) {
      setPreviewData(pageStructureData)
    }
  }, [isStory])

  useEffect(() => {
    if (isBulletPoints) {
      setShowBulletPointsForm(true)
    }
  }, [isBulletPoints])

  const handlePageClick = i => {
    if (isSelectable) {
      handleSelection(i)
    } else {
      onPreviewItemClick(i)
    }
  }

  function extractSelectedPageText() {
    const selectedPages = previewData.filter(page => page.pageSelected)

    let concatenatedText = ''

    selectedPages.forEach(page => {
      page.data.forEach(element => {
        if (element.type === 'TEXT' && element.display !== 'HEADING') {
          const textContent =
            new DOMParser().parseFromString(element.params.content, 'text/html')
              .body.textContent || ''
          concatenatedText += textContent + ' '
        }
      })
    })

    return concatenatedText
      .replace(/I could not find content related to this topic/g, '') // Remove unwanted phrase
      .replace(/\s+/g, ' ') // Normalize extra spaces
      .trim()
  }

  const extractCurrentPageText = () => {
    const currentPageData = previewData[currentPage - 1]

    let concatenatedText = ''

    currentPageData.data.forEach(element => {
      if (element.type === 'TEXT' && element.display !== 'HEADING') {
        const textContent =
          new DOMParser().parseFromString(element.params.content, 'text/html')
            .body.textContent || ''
        concatenatedText += textContent + ' '
      }
    })

    return concatenatedText
      .replace(/I could not find content related to this topic/g, '') // Remove unwanted phrase
      .replace(/\s+/g, ' ') // Normalize extra spaces
      .trim()
  }

  const extractedText = useMemo(() => {
    return extractSelectedPageText()
  }, [previewData])

  const handleSelection = (i) => {
    if (containerRef.current) {
      const scrollTop = containerRef.current.scrollTop;
      setPreviewData(pre =>
        pre.map((page, index) => ({
          ...page,
          pageSelected: index === i ? !page.pageSelected : page.pageSelected || false,
        }))
      );
      requestAnimationFrame(() => {
        if (containerRef.current) {
          containerRef.current.scrollTop = scrollTop;
        }
      });
    }
  };

  const canGenerateStory = () => {
    const selectedPages = previewData.filter(page => page.pageSelected)
    return selectedPages.length > 0
  }

  const onClear = () => {
    setShowStoryCreationForm(false)
    setShowScenario(false)
    setShowQuizForm(false)
    setShowIntroductionForm(false)
    setShowSummaryForm(false)
    setShowBulletPointsForm(false)
    setPreviewData(pageStructureData)
    handleType(null)
  }

  const mapOrder = array =>
    array.map((item, index) => ({ ...item, ordering: index + 1 }))

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return
    const { pages, pageStructureData, lessonUpdate } = props
    const newPages = mapOrder(arrayMove(pages, oldIndex, newIndex))
    const newLandscapePages = mapOrder(
      arrayMove(pageStructureData, oldIndex, newIndex)
    )

    setPreviewData(newLandscapePages)

    dispatch(lessonBuilderActions.handleLessonInput('pages', newPages))
    dispatch(
      lessonBuilderActions.handleLessonInput(
        'pages_landscape',
        newLandscapePages
      )
    )
    lessonUpdate({ pages: newPages, pages_landScape: newLandscapePages })
  }

  const handleGenerateButton = () => {
    if (isStory) {
      setShowStoryCreationForm(true)
    } else if (isScenario) {
      setShowScenario(true)
    } else if (isQuizAI) {
      setShowQuizForm(true)
    } else if (isIntroduction) {
      setShowIntroductionForm(true)
    } else if (isSummary) {
      setShowSummaryForm(true)
    }
  }

  const removeLessonPage = number => {
    const { pages, pageStructureData, lessonUpdate } = props
    const newPages = pages.filter((page, index) => index !== number)
    const newLandscapePages = pageStructureData.filter(
      (page, index) => index !== number
    )
    setPreviewData(newLandscapePages)
    dispatch(lessonBuilderActions.handleLessonInput('pages', newPages))
    dispatch(
      lessonBuilderActions.handleLessonInput(
        'pages_landscape',
        newLandscapePages
      )
    )
    lessonUpdate({ pages: newPages, pages_landScape: newLandscapePages })
  }

  const { t } = useTranslation()

  const PageItem = SortableElement(({ children, className }) => {
    return <div className={className}>{children}</div>
  })

  const PagesContainer = SortableContainer(({ children, className }) => {
    return <div className={className} ref={containerRef}>{children}</div>
  })
  return (
    <div className="page-builder-preview-container">
      <span className="lesson-title">{lessonTitle}</span>
      <PagesContainer
        distance={10}
        onSortEnd={onSortEnd}
        lockAxis="y"
        disableAutoscroll
        lockToContainerEdges
        className="page-builder-preview-list"
        useDragHandle={isSelectable}
      >

        {previewData &&
          previewData.map((page, index) => {
            if (!page) return null

            const currentPageStructure = page.data || []
            const isCurrentPage = !isSelectable && currentPage === index + 1
            const selected = isSelectable && page.pageSelected

            return (
              <PageItem key={index} index={index} className="page-item">
                <div
                  key={index}
                  className={`page-builder-preview 
            ${isCurrentPage ? 'active' : ''}
            ${selected ? 'selected' : ''}`}
                  onClick={() => {
                    handlePageClick(index);
                  }}
                >
                  <Builder.Page
                    {...props}
                    pageStructure={currentPageStructure}
                    isPreview
                  />
                  <span className="page-number">{index + 1}</span>

                  {selected && (
                    <span className="selected-check">
                      <Icon type="check" />
                    </span>
                  )}
                </div>
                {/* delete page */}
                {!isSelectable && (
                  <Popconfirm
                    title={t('warnings:delete')}
                    onConfirm={() => removeLessonPage(index)}
                  >
                    <span className="delete-page">
                      <img src={DeleteIcon} alt="delete-icon" />
                    </span>
                  </Popconfirm>
                )}
              </PageItem>
            )
          })}
      </PagesContainer>

      {!isSelectable && (
        <div className="page-builder-preview add" onClick={() => toPage('new')}>
          <div className="add-new-page">
            <span>+</span>
            <p>{t('buttons:add_page')}</p>
          </div>
        </div>
      )}

      {isSelectable && (
        <div className="create-story">
          <Button
            type="green"
            shape="round"
            icon="plus"
            disabled={!canGenerateStory()}
            onClick={handleGenerateButton}
          >
            {isStory
              ? t('v4:create_story')
              : isQuizAI
                ? t('v4:generate_quiz')
                : isScenario
                  ? t('v3:generate_scenario')
                  : isIntroduction
                    ? t('v4:generate_introduction')
                    : t('v4:generate_summary')}
          </Button>
        </div>
      )}

      {showStoryCreationForm && (
        <StoryCreationForm
          {...props}
          previewData={previewData}
          onCancel={onClear}
        />
      )}

      {showScenario && (
        <ScenarioHandler
          {...props}
          extractedText={extractedText}
          isShowScenarioModal={isShowScenarioModal}
          scenarioId={scenarioIndex}
          lessonId={lessonId}
          courseId={courseId}
          onClear={onClear}
        />
      )}

      {showQuizForm && (
        <AiQuiz {...props} previewData={previewData} onCancel={onClear} />
      )}

      {(showIntroductionForm || showSummaryForm) && (
        <IntroOrSummary
          {...props}
          onCancel={onClear}
          extractedText={extractedText}
          isIntroduction={showIntroductionForm}
        />
      )}

      {/* only extract content from current page */}
      {showBulletPointsForm && (
        <BulletPoints
          {...props}
          onCancel={onClear}
          extractedText={extractCurrentPageText()}
        />
      )}
    </div>
  )
}

export default PagePreviewSection
