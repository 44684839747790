import React from 'react'
import { Modal, Button, Icon } from 'antd'
import { ChromePicker } from 'react-color'

const BackgroundColorSettings = ({
  visible,
  onCancel,
  onRemove,
  selectedColor,
  tempColor,
  setTempColor,
  onConfirm
}) => {
  const handleColorChange = color => {
    // Ensure the color includes the alpha value (rgba)
    setTempColor(
      color.rgb
        ? `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
        : color.hex
    )
  }

  const ColorPreview = ({ color, label }) => (
    <div
      style={{
        border: '1px solid #d9d9d9',
        borderRadius: 4,
        padding: 8,
        backgroundColor: '#fafafa',
        flex: 1
      }}
    >
      <div
        style={{
          backgroundColor: color,
          width: '100%',
          height: 80,
          borderRadius: 2,
          boxShadow: 'inset 0 0 0 1px rgba(0,0,0,0.1)'
        }}
      />
      <div
        style={{
          marginTop: 8,
          textAlign: 'center',
          color: 'rgba(0, 0, 0, 0.45)',
          fontSize: '12px'
        }}
      >
        {label}
        <div style={{ color: 'rgba(0, 0, 0, 0.85)', marginTop: 4 }}>
          {color}
        </div>
      </div>
    </div>
  )

  return (
    <Modal
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon type="bg-colors" style={{ marginRight: 8 }} />
          Background Color Settings
        </div>
      }
      visible={visible}
      onCancel={onCancel}
      width={520}
      footer={null}
      centered
      bodyStyle={{ padding: '24px' }}
    >
      <div>
        {(selectedColor || tempColor) && (
          <div style={{ marginBottom: 24 }}>
            <h4
              style={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.85)',
                marginBottom: 16
              }}
            >
              Color Preview
            </h4>
            <div style={{ display: 'flex', gap: 16 }}>
              {selectedColor && (
                <ColorPreview color={selectedColor} label="Current Color" />
              )}
              {tempColor && tempColor !== selectedColor && (
                <ColorPreview color={tempColor} label="New Color" />
              )}
            </div>
          </div>
        )}

        <div style={{ marginBottom: 24 }}>
          <h4
            style={{
              fontSize: '14px',
              color: 'rgba(0, 0, 0, 0.85)',
              marginBottom: 16,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <span>Choose New Color</span>
          </h4>
          <div
            style={{
              border: '1px solid #d9d9d9',
              borderRadius: 4,
              padding: 16,
              backgroundColor: '#fafafa',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <ChromePicker
              color={tempColor || selectedColor || '#fff'}
              onChange={handleColorChange}
              alpha={true} // Ensure that the alpha transparency slider is visible
              styles={{
                default: {
                  picker: {
                    width: '100%',
                    boxShadow: 'none',
                    fontFamily: 'inherit'
                  }
                }
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: selectedColor ? 'space-between' : 'flex-end',
            gap: 8,
            marginTop: 24
          }}
        >
          {selectedColor && (
            <Button
              onClick={onRemove}
              icon="delete"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              Remove Color
            </Button>
          )}
          <div style={{ display: 'flex', gap: 8 }}>
            <Button
              type="primary"
              onClick={onConfirm}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Icon type="check" />
              Apply Color
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default BackgroundColorSettings
