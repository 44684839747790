import styled from 'styled-components'

const AudioPlayerStyle = styled.div`
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '100%')};

  audio {
    width: ${props => (props.width ? props.width : '100%')};
    height: ${props => (props.height ? props.height : '100%')};
    border: 1px solid #bdcadb;
    box-sizing: border-box;
    border-radius: 10px;
    background: #f1f3f4;
  }

  audio::-webkit-media-controls-panel {
    display: ${props =>
      props.width && parseInt(props.width) <= 50 ? 'none' : 'flex'};
  }

  audio::-webkit-media-controls-play-button {
    display: block;
  }
`

AudioPlayerStyle.Center = styled.div`
  text-align: center;
`

export { AudioPlayerStyle }
