import React, { memo } from 'react'
import { Input, Select, Tag, Button, Row, Col, Divider } from 'antd'
import { attributes } from '../helper/data.character'
import { capitaliseFirstLetter } from '../helper/utils.character'

const { Option } = Select

const CustomizeCharacterForm = ({
  characterName,
  onNameChange,
  characterParams,
  onParamChange,
  generatedCharacterTags,
  onCreateCharacter
}) => (
  <div className="character-settings">
    <h3>Create New Character</h3>
    <p>Fill in the details to create your character</p>

    <Divider />

    <CharacterNameInput value={characterName} onChange={onNameChange} />

    <Divider />

    <Row gutter={[16, 16]}>
      {attributes.map(param => (
        <AttributeSelect
          key={param.name}
          param={param}
          onChange={onParamChange}
        />
      ))}
    </Row>

    {generatedCharacterTags.length > 0 && (
      <>
        <Divider />
        <h3 className="selected-params">Selected Character Parameters</h3>
        {generatedCharacterTags.map((tag, index) => (
          <Tag
            key={index}
            color={tag.color}
            style={{
              fontSize: '14px',
              padding: '5px 10px',
              margin: '5px',
              borderRadius: '4px'
            }}
          >
            {capitaliseFirstLetter(tag.param)} -{' '}
            {capitaliseFirstLetter(tag.label)}
          </Tag>
        ))}
      </>
    )}

    <div className="action-buttons" style={{ marginTop: '24px' }}>
      <Button onClick={onCreateCharacter} type="primary" size="large">
        Create Character
      </Button>
    </div>
  </div>
)

const AttributeSelect = memo(({ param, onChange }) => (
  <Col xs={24} sm={12} md={8} lg={6}>
    <div
      className="select-container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%'
      }}
    >
      <div className="ant-form-item-label" style={{ marginBottom: '8px' }}>
        <label>{param.label}</label>
      </div>
      <Select
        placeholder={`Select ${param.label}`}
        onChange={value => onChange(param.name, value)}
        style={{ width: '100%' }}
      >
        {param.options.map(option => (
          <Option key={option} value={option}>
            {capitaliseFirstLetter(option)}
          </Option>
        ))}
      </Select>
    </div>
  </Col>
))

const CharacterNameInput = memo(({ value, onChange }) => (
  <Row gutter={[16, 16]}>
    <Col span={24}>
      <div
        className="select-container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%'
        }}
      >
        <div className="ant-form-item-label" style={{ marginBottom: '8px' }}>
          <label>Character Name</label>
        </div>
        <Input
          placeholder="Enter character name"
          value={value}
          onChange={e => onChange(e.target.value)}
          style={{ width: '100%' }}
        />
      </div>
    </Col>
  </Row>
))

export default CustomizeCharacterForm
