import React, { useState } from 'react'
import {
  Button,
  Input,
  message,
  Drawer,
  Popover,
  Icon,
  Select,
  Tag,
  Upload,
  Menu,
  Dropdown
} from 'antd'
import ReactPlayer from 'react-player'
import './VideoGeneration.scss'
import CommentInput from '../CommentInput'

import { transitions, scenes as defaultScenes } from './helper/data.video'
import {
  DownOutlined,
  ReloadOutlined,
  SettingOutlined,
  UploadOutlined
} from '@ant-design/icons'
import StepTitle from '../StepTitle'

const { TextArea } = Input

const TransitionDisplay = ({ type, onRemove }) => {
  const transition = transitions.find(t => t.id === type) || null

  if (!transition) {
    return <Icon type="plus" className="video-transition-icon" />
  }

  return (
    <div className="selected-transition">
      <h3>Transition</h3>

      <Tag
        closable
        onClose={e => {
          e.preventDefault()
          onRemove(type)
        }}
        className="selected-transition__tag"
      >
        <Icon type={transition.icon} className="video-transition-icon" />
        {transition.name}
      </Tag>
    </div>
  )
}

const TransitionSelector = ({ onSelect, currentTransition }) => {
  return (
    <div className="transition-selector">
      <p className="selector-title">Select Transition</p>
      <div className="transition-options">
        <Button
          key="none"
          className={`transition-option ${
            !currentTransition ? 'selected' : ''
          }`}
          onClick={() => onSelect(null)} // This will remove the transition
        >
          <Icon type="close" />
          <span>None</span>
        </Button>
        {transitions.map(transition => (
          <Button
            key={transition.id}
            className={`transition-option ${
              currentTransition === transition.id ? 'selected' : ''
            }`}
            onClick={() => onSelect(transition.id)}
          >
            <Icon type={transition.icon} />
            <span>{transition.name}</span>
          </Button>
        ))}
      </div>
    </div>
  )
}

const VideoGeneration = ({ formData }) => {
  const [scenes, setScenes] = useState(defaultScenes)
  const [selectedSettings, setSelectedSettings] = useState(null)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [transitions, setTransitions] = useState({})

  const handleRegenerateVideo = (sceneId, shotId) => {
    message.success(`Scene ${sceneId}, Shot ${shotId} regenerated!`)
  }

  const handleEditSettings = (sceneId, shotId) => {
    const scene = scenes.find(s => s.id === sceneId)
    const shot = scene.shots.find(sh => sh.id === shotId)

    setSelectedSettings({
      sceneId,
      shotId,
      ...scene.settings,
      ...shot,
      motion: shot.motion || 'Static', // Default to 'Static' if motion is not set
      sceneMotion: shot.sceneMotion || 'Natural motion' // Default to 'Natural motion'
    })
    setDrawerVisible(true)
  }

  const updateSettings = (key, value) => {
    setSelectedSettings(prev => ({
      ...prev,
      [key]: value
    }))
  }

  const saveSettings = () => {
    setScenes(prevScenes =>
      prevScenes.map(scene => {
        if (scene.id === selectedSettings.sceneId) {
          return {
            ...scene,
            shots: scene.shots.map(shot =>
              shot.id === selectedSettings.shotId
                ? {
                    ...shot,
                    prompt: selectedSettings.prompt,
                    shotType: selectedSettings.shotType,
                    clipDuration: selectedSettings.clipDuration,
                    motion: selectedSettings.motion,
                    sceneMotion: selectedSettings.sceneMotion
                  }
                : shot
            )
          }
        }
        return scene
      })
    )
    setDrawerVisible(false)
  }

  const handleTransitionSelect = (sceneId, position, transitionType) => {
    setTransitions(prev => ({
      ...prev,
      [`${sceneId}-${position}`]:
        transitionType === null ? undefined : transitionType
    }))

    if (transitionType === null) {
      message.success('Transition removed')
    } else {
      message.success(`Transition updated to ${transitionType}`)
    }
  }

  const handleUploadVideo = (sceneId, shotId, file) => {
    if (!file) return

    if (!file.type.includes('video/')) {
      message.error('Please upload a video file')
      return
    }

    const videoUrl = URL.createObjectURL(file)

    setScenes(prevScenes =>
      prevScenes.map(scene => {
        if (scene.id === sceneId) {
          return {
            ...scene,
            shots: scene.shots.map(shot =>
              shot.id === shotId ? { ...shot, src: videoUrl } : shot
            )
          }
        }
        return scene
      })
    )
    message.success('Video uploaded successfully!')
  }

  return (
    <div className="video-generation-container">
      <div className="header">
        <StepTitle
          iconType="video-camera"
          title="Video Generation"
          description="Create videos for your project"
        />

        <Button
          type="green"
          icon="play-circle"
          className="generate-video-button"
          onClick={() => message.success('Video generated successfully!')}
        >
          Generate Full Video
        </Button>
      </div>

      {scenes.map(scene => (
        <div key={scene.id} className="scene-container">
          <div className="left-panel">
            <h2>{scene.title}</h2>
            <TextArea
              value={scene.description}
              rows={2}
              onChange={e => {
                const newDescription = e.target.value
                setScenes(prevScenes =>
                  prevScenes.map(s =>
                    s.id === scene.id
                      ? { ...s, description: newDescription }
                      : s
                  )
                )
              }}
            />
          </div>

          <div className="right-panel">
            <div className="shots-grid">
              {/* Opening transition */}
              <div className="transition-wrapper start-transition">
                <Popover
                  content={
                    <TransitionSelector
                      onSelect={type =>
                        handleTransitionSelect(scene.id, 'start', type)
                      }
                      currentTransition={transitions[`${scene.id}-start`]}
                    />
                  }
                  trigger="hover"
                  placement="right"
                >
                  <div className="transition-trigger">
                    <TransitionDisplay
                      type={transitions[`${scene.id}-start`]}
                      onRemove={() =>
                        handleTransitionSelect(scene.id, 'start', null)
                      }
                    />
                  </div>
                </Popover>
              </div>

              {scene.shots.map((shot, index) => (
                <React.Fragment key={shot.id}>
                  <div className="shot-card">
                    <h3 className="shot-title">Shot {index + 1}</h3>
                    <div className="video-wrapper">
                      <ReactPlayer
                        url={
                          shot.src ||
                          'https://www.w3schools.com/html/mov_bbb.mp4'
                        }
                        width="100%"
                        height="250px"
                        controls={true}
                        config={{
                          file: {
                            attributes: {
                              controlsList: 'nodownload' // Disable download option
                            }
                          }
                        }}
                      />
                    </div>
                    <CommentInput
                      characters={formData.characterList}
                      text={shot.prompt}
                      placeholder="Write your video prompt..."
                    />
                    {/* <Input value={shot.shotType} placeholder="Shot Type" /> */}
                    <div className="card-actions">
                      <Dropdown
                        trigger={['click']}
                        overlay={
                          <Menu className="video-generation-menu">
                            <Menu.Item
                              onClick={() =>
                                handleRegenerateVideo(scene.id, shot.id)
                              }
                            >
                              <ReloadOutlined /> Regenerate Video
                            </Menu.Item>
                            <Menu.Item
                              onClick={() =>
                                document
                                  .getElementById(
                                    `upload-input-${scene.id}-${shot.id}`
                                  )
                                  .click()
                              }
                            >
                              <UploadOutlined /> Upload Your Own Video
                              <input
                                id={`upload-input-${scene.id}-${shot.id}`}
                                type="file"
                                accept="video/*"
                                hidden
                                onChange={e => {
                                  const file = e.target.files[0]
                                  if (file)
                                    handleUploadVideo(scene.id, shot.id, file)
                                  e.target.value = null // Reset input to allow same file selection
                                }}
                              />
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <Button>
                          Actions <DownOutlined />
                        </Button>
                      </Dropdown>

                      <Button
                        onClick={() => handleEditSettings(scene.id, shot.id)}
                      >
                        <SettingOutlined /> Settings
                      </Button>
                    </div>
                  </div>

                  {/* Only show between-shots transition if it's not the last shot */}
                  {index < scene.shots.length - 1 && (
                    <div className="transition-wrapper">
                      <Popover
                        content={
                          <TransitionSelector
                            onSelect={type =>
                              handleTransitionSelect(scene.id, shot.id, type)
                            }
                            currentTransition={
                              transitions[`${scene.id}-${shot.id}`]
                            }
                          />
                        }
                        trigger="hover"
                        placement="right"
                      >
                        <div className="transition-trigger">
                          <TransitionDisplay
                            type={transitions[`${scene.id}-${shot.id}`]}
                            onRemove={() =>
                              handleTransitionSelect(scene.id, shot.id, null)
                            }
                          />
                        </div>
                      </Popover>
                    </div>
                  )}
                </React.Fragment>
              ))}

              {/* Only show end transition after the last shot */}
              <div className="transition-wrapper end-transition">
                <Popover
                  content={
                    <TransitionSelector
                      onSelect={type =>
                        handleTransitionSelect(scene.id, 'end', type)
                      }
                      currentTransition={transitions[`${scene.id}-end`]}
                    />
                  }
                  trigger="hover"
                  placement="right"
                >
                  <div className="transition-trigger">
                    <TransitionDisplay
                      type={transitions[`${scene.id}-end`]}
                      onRemove={() =>
                        handleTransitionSelect(scene.id, 'end', null)
                      }
                    />
                  </div>
                </Popover>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Settings Drawer */}
      <Drawer
        title="Edit Shot Settings"
        placement="right"
        closable={true}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        width={400}
      >
        {selectedSettings && (
          <div className="settings-drawer-content">
            {/* Previous settings */}

            <div className="setting-group">
              <label>Motion</label>
              <Select
                value={selectedSettings.motion}
                onChange={e => updateSettings('motion', e.target.value)}
              >
                <Select.Option value="Static">Static</Select.Option>
                <Select.Option value="Track down">Track down</Select.Option>
                <Select.Option value="Track up">Track up</Select.Option>
                <Select.Option value="Pan left">Pan left</Select.Option>
                <Select.Option value="Pan right">Pan right</Select.Option>
                <Select.Option value="Tilt up">Tilt up</Select.Option>
                <Select.Option value="Tilt down">Tilt down</Select.Option>
                <Select.Option value="Dolly in">Dolly in</Select.Option>
                <Select.Option value="Dolly out">Dolly out</Select.Option>
              </Select>
            </div>

            <div className="setting-group">
              <label>Scene Motion</label>
              <Select
                value={selectedSettings.sceneMotion}
                onChange={e => updateSettings('sceneMotion', e.target.value)}
              >
                <Select.Option value="Natural motion">
                  Natural motion
                </Select.Option>
                <Select.Option value="Extreme motion">
                  Extreme motion
                </Select.Option>
              </Select>
            </div>

            <Button
              type="primary"
              onClick={saveSettings}
              style={{ marginTop: 16 }}
            >
              Save Settings
            </Button>
          </div>
        )}
      </Drawer>
    </div>
  )
}

export default VideoGeneration
