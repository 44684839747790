import React, { useEffect, useState } from 'react'
import { Tabs, message } from 'antd'
import {
  FormOutlined,
  UploadOutlined,
  DatabaseOutlined
} from '@ant-design/icons'
import { useCharacterCreation } from './helper/utils.character'
import { existingCharacters as initialExistingCharacters } from './helper/data.character'
import CustomizeCharacterForm from './components/CustomizeCharacterForm'
import ImageUploader from './components/ImageUploader'
import ExistingCharacters from './components/ExistingCharacters'
import StepTitle from '../StepTitle'

const { TabPane } = Tabs

const SelectCharacter = ({ formData, setFormData, onValidationChange }) => {
  const [activeTab, setActiveTab] = useState('customize')
  const [selectedCharacter, setSelectedCharacter] = useState(null)
  const [image, setImage] = useState(null)
  const [existingCharacters, setExistingCharacters] = useState(
    initialExistingCharacters
  )

  const {
    characterName,
    setCharacterName,
    characterParams,
    handleParamChange,
    generatedCharacterTags,
    resetCharacterCreation
  } = useCharacterCreation(
    existingCharacters,
    setExistingCharacters,
    handleCharacterCreated
  )

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      characterList: existingCharacters.map(c => c.name)
    }))
  }, [existingCharacters])

  function handleCharacterCreated(newCharacter) {
    setExistingCharacters(prevCharacters => [...prevCharacters, newCharacter])
    setSelectedCharacter(newCharacter)
    setActiveTab('existing')
    resetCharacterCreation()
    updateFormData(newCharacter)
    message.success('Character created successfully!')
    onValidationChange(prev => ({ ...prev, characterSelection: true }))
  }

  function handleImageUpload(file) {
    const reader = new FileReader()
    reader.onload = e => setImage(e.target.result)
    reader.readAsDataURL(file)
    return false
  }

  function updateFormData(character) {
    setFormData(prev => ({
      ...prev,
      selectedCharacter: character
    }))
  }

  function handleCreateCharacter() {
    if (!characterName.trim()) {
      message.error('Please enter a character name!')
      return
    }
    if (Object.values(characterParams).includes('')) {
      message.error('Please select all character parameters!')
      return
    }

    const newCharacter = {
      id: existingCharacters.length + 1,
      name: characterName,
      image: image || 'https://via.placeholder.com/300x400',
      tags: generatedCharacterTags.map(tag => tag.label),
      characterType: 'custom',
      prompt: `A ${characterParams.gender} ${characterParams.age} with ${characterParams.appearance} appearance, wearing ${characterParams.attire}`
    }

    handleCharacterCreated(newCharacter)
  }

  function handleSelectExisting() {
    if (!selectedCharacter) {
      message.error('Please select a character!')
      return
    }

    updateFormData(selectedCharacter)
    message.success('Character selected successfully!')
    onValidationChange(prev => ({ ...prev, characterSelection: true }))
  }

  return (
    <div className="select-character-container">
      <StepTitle
        iconType="user"
        title="Character Selection"
        description="Choose how you want to create your character"
      />

      <Tabs activeKey={activeTab} onChange={setActiveTab} centered>
        <TabPane
          tab={
            <span>
              <FormOutlined />
              Custom Character
            </span>
          }
          key="customize"
        >
          <CustomizeCharacterForm
            characterName={characterName}
            onNameChange={setCharacterName}
            characterParams={characterParams}
            onParamChange={handleParamChange}
            generatedCharacterTags={generatedCharacterTags}
            onCreateCharacter={handleCreateCharacter}
          />
        </TabPane>

        <TabPane
          tab={
            <span>
              <UploadOutlined />
              Upload Image
            </span>
          }
          key="upload"
        >
          <ImageUploader
            onImageUpload={handleImageUpload}
            previewImage={image}
          />
        </TabPane>

        <TabPane
          tab={
            <span>
              <DatabaseOutlined />
              Existing Characters
            </span>
          }
          key="existing"
        >
          <ExistingCharacters
            characters={existingCharacters}
            selectedCharacter={selectedCharacter}
            onCharacterSelect={setSelectedCharacter}
            onSelectConfirm={handleSelectExisting}
          />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default SelectCharacter
