import { Button, Input, Modal, Icon, Spin, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { api } from '../../../../../../../../../services'
import nanoid from 'nanoid'
import actions from '../../../../../../../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'

import './IntroOrSummary.scss'

const IntroOrSummary = props => {
  const [isGenerating, setIsGenerating] = useState(false)
  const [text, setText] = useState('')
  const dispatch = useDispatch()

  const { handleLessonInput } = actions.lessonBuilder

  const {
    extractedText,
    lessonTitle,
    onCancel,
    isIntroduction = false,
    pageStructureData,
    lessonUpdate,
    pages,
    currentPage
  } = props

  useEffect(() => {
    startGeneration()
  }, [])

  const courseTitle = useSelector(state => state.courseBuilder.courseTitle)

  const startGeneration = () => {
    setIsGenerating(true)

    api.courses
      .generateIntroOrSummary({
        content: extractedText,
        isIntroduction: isIntroduction,
        isSummary: false,
        course_name: courseTitle,
        topic: lessonTitle
      })
      .then(res => {
        if (res.data.body) {
          setText(res.data.body)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setIsGenerating(false)
      })
  }

  const applyToPage = () => {
    const newPageData = {
      size: { width: 1024, height: 768 },
      orientation: 'landscape',
      data: [
        {
          uid: nanoid(8),
          type: 'TEXT',
          display: 'HEADING',
          params: {
            content: `<h1 class=\"ql-align-center\">${
              isIntroduction ? 'INTRODUCTION' : 'SUMMARY'
            }</h1>`,
            x: 57,
            y: 50,
            width: 900,
            height: 67,
            media_format: 'TEXT'
          }
        },
        {
          uid: nanoid(8),
          type: 'TEXT',
          display: 'CONTENT',
          params: {
            content: text,
            x: 57,
            y: 146,
            width: 900,
            height: 353
          }
        }
      ]
    }

    const newPage = {
      data: [],
      orientation: 'portrait',
      size: { width: 1024, height: 768 }
    }

    const newData = [...pageStructureData]
    const newPages = [...pages]

    if (isIntroduction) {
      newData.unshift(newPageData)
      newPages.unshift(newPage)
    } else {
      newData.push(newPageData)
      newPages.push(newPage)
    }

    dispatch(handleLessonInput('pages_landscape', newData))
    dispatch(handleLessonInput('pages', newPages))
    dispatch(handleLessonInput('currentPage', isIntroduction ? 1 : currentPage))
    lessonUpdate({ pages_landScape: newData, pages: newPages })

    message.success(
      isIntroduction
        ? 'Introduction added at the beginning of the lesson.'
        : 'Summary added at the end of the lesson.'
    )
    onCancel()
  }

  const getDescription = () => {
    if (isIntroduction) {
      return "Generate an engaging introduction to capture your audience's attention and set the tone for your lesson."
    }
    return 'Create a comprehensive summary that reinforces key points and wraps up your lesson effectively.'
  }

  console.log({ props })

  return (
    <Modal
      visible={true}
      onCancel={onCancel}
      width={800}
      footer={null}
      centered
      title={
        <div className="intro-summary-head">
          <div className="intro-summary-head-icon">
            <Icon
              type={isIntroduction ? 'book' : 'file-text'}
              style={{ fontSize: 20, color: '#1890ff' }}
            />
          </div>
          <div className="intro-summary-head-text">
            <h3 style={{ fontSize: 16, fontWeight: 500 }}>
              {isIntroduction ? 'Introduction' : 'Summary'}
            </h3>
            <p>{getDescription()}</p>
          </div>
        </div>
      }
      style={{ top: 20 }}
    >
      {isGenerating ? (
        <div
          style={{
            padding: '40px 0',
            textAlign: 'center',
            background: '#f4f5fa',
            borderRadius: 4,
            color: '#666'
          }}
        >
          <Spin style={{ fontSize: 24, marginBottom: 16 }} />
          <p style={{ margin: 0 }}>
            Generating {isIntroduction ? 'introduction' : 'summary'}...
          </p>
          <p style={{ margin: '8px 0 0', fontSize: 12, color: '#999' }}>
            This may take a few moments
          </p>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 20
          }}
        >
          <div style={{ position: 'relative' }}>
            <Input.TextArea
              autoSize={{ minRows: 8, maxRows: 20 }}
              value={text}
              style={{
                padding: '12px 16px',
                fontSize: '14px',
                lineHeight: '1.6',
                border: '1px solid #d9d9d9',
                borderRadius: '4px'
              }}
            />
            <Icon
              type="edit"
              style={{
                position: 'absolute',
                right: 12,
                top: 12,
                color: '#999'
              }}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 12 }}>
            <Button onClick={onCancel}>
              <Icon type="close" />
              Cancel
            </Button>
            <Button type="primary" onClick={applyToPage}>
              <Icon type="check" />
              Apply to Page
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default IntroOrSummary
