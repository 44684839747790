import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Form, Input, Button, Tooltip, Icon, Checkbox, Row, Col } from 'antd'
import { withTranslation } from 'react-i18next'
import { PasswordInput } from 'antd-password-input-strength'
// import ReactPhoneInput from 'react-phone-input-2'
// import libphonenumber from 'google-libphonenumber'
// import { countries } from '../../../../../constants'
import './RegisterForm.scss'

// const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()
// const PNF = libphonenumber.PhoneNumberFormat

class RegisterForm extends Component {
  state = {
    checked: false,
    showOrgInput: false,
    organizationID: '',
    organizationName: ''
  }

  handleSubmit = e => {
    e.preventDefault()

    const { signUp } = this.props
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const params = {
          ...values,
          phone_number: '+6562000000', // Default Singapore phone number format
          username: values['username'].toLowerCase(),
          email: values['email'].toLowerCase(),
          // organization: values['custom:organization'].toLowerCase()
          ...(this.state.organizationName && {
            'custom:organizationID': this.state.organizationID
          })
        }
        signUp(params)
      }
    })
  }
  componentDidMount() {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const isSharebot = params.get('isSharebot')
    const organizationName = params.get('organizationName')
    const organizationID = params.get('organizationId')

    if (isSharebot === 'true') {
      this.setState({ showOrgInput: true })
    }
    if (organizationName) {
      this.setState({
        organizationID: organizationID,
        organizationName: organizationName
      })
    }
  }
  handleChange = e => {
    this.setState({
      checked: e.target.checked
    })
  }

  validatePassword = (rule, value, callback) => {
    const { t } = this.props

    if (value) {
      if (value.length < 10) {
        callback(t('errors:password_short'))
      }

      if (!value.match(/[a-z]+/)) {
        callback(t('errors:password_lowercase'))
      }

      if (!value.match(/[0-9]+/)) {
        callback(t('errors:password_digit'))
      }

      callback()
    } else {
      callback(t('errors:password'))
    }
  }

  validateUsername = (rule, value, callback) => {
    const { t } = this.props

    if (value) {
      if (value.indexOf(' ') >= 0) callback(t('v3:error_username_space'))
      if (value.length < 3) {
        return callback('Username should contain at least 3 characters.')
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (emailRegex.test(value)) {
        return callback(t('v3:error_username_email'))
      }

      const usernameRegex = /^\S+@\S+$/

      if (usernameRegex.test(value)) {
        return callback(
          'Username should not be in the format of an email address.'
        )
      }

      callback()
    } else {
      callback(t('errors:username'))
    }
  }

  validateFirstName = (rule, value, callback) => {
    const { t } = this.props
    if (value) {
      if (value.indexOf(' ') >= 0) callback(t('v3:error_name_spaces'))
      if (!value.match(/^[A-Za-z]+$/)) callback(t('v3:error_valid_firstName'))
      callback()
    } else {
      callback(t('v3:error_firstName'))
    }
  }

  validateLastName = (rule, value, callback) => {
    const { t } = this.props
    if (value) {
      if (value.indexOf(' ') >= 0) callback(t('v3:error_name_spaces'))
      if (!value.match(/^[A-Za-z]+$/)) callback(t('v3:error_valid_lastName'))
      callback()
    } else {
      callback(t('v3:error_lastName'))
    }
  }

  validateOrganization = (rule, value, callback) => {
    const { t } = this.props

    if (value) {
      if (!value.match(/^[A-Za-z\s]+$/)) {
        callback(t('v3:error_valid_organization'))
      } else {
        callback() // Validation successful
      }
    } else if (this.state.organizationName) {
      callback()
    } else {
      callback(t('v3:error_sharebot_organization'))
    }
  }

  onSharebotChange = e => {
    // console.log(`checked = ${e.target.checked}`)
    this.setState({
      showOrgInput: e.target.checked
    })
  }

  render() {
    const { checked, showOrgInput, organizationName } = this.state
    const {
      form: { getFieldDecorator },
      loading,
      prefilledEmail,
      t
    } = this.props

    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={[16, 16]} style={{ marginBottom: '15px' }}>
            <Col md={12}>
              <Form.Item>
                {getFieldDecorator('custom:firstName', {
                  rules: [
                    {
                      validator: this.validateFirstName
                    }
                  ]
                })(
                  <Input
                    placeholder={t('v3:first_name')}
                    name="custom:firstName"
                    type="text"
                    maxLength={320}
                  />
                )}
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item>
                {getFieldDecorator('custom:lastName', {
                  rules: [
                    {
                      validator: this.validateLastName
                    }
                  ]
                })(
                  <Input
                    placeholder={t('v3:last_name')}
                    name="custom:lastName"
                    maxLength={320}
                    type="text"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: t('errors:email_invalid')
                },
                {
                  required: true,
                  message: t('errors:email'),
                  max: 320
                }
              ],
              initialValue: prefilledEmail
            })(
              <Input
                placeholder={t('labels:email')}
                name="email"
                disabled={!!prefilledEmail}
                maxLength={320}
              />
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('username', {
              rules: [
                {
                  validator: this.validateUsername
                }
              ]
            })(
              <Input
                placeholder={t('labels:username')}
                name="username"
                maxLength={320}
                addonAfter={
                  <Tooltip title={t('helpers:username')}>
                    <Icon type="question-circle-o" />
                  </Tooltip>
                }
              />
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('password', {
              rules: [
                {
                  validator: this.validatePassword
                }
              ]
            })(
              <PasswordInput
                placeholder={t('labels:password')}
                name="password"
                addonAfter={
                  <Tooltip title={t('helpers:password')}>
                    <Icon type="question-circle-o" />
                  </Tooltip>
                }
              />
            )}
          </Form.Item>

          {/* <Form.Item>
            <Checkbox onChange={this.onSharebotChange} checked={showOrgInput}>
              Create Organization ?
            </Checkbox>
          </Form.Item> */}

          {showOrgInput && (
            <Form.Item>
              {getFieldDecorator('custom:organization', {
                rules: [
                  {
                    validator: this.validateOrganization
                  }
                ]
              })(
                <Input
                  placeholder={t('v3:sharebot_organization')}
                  name="custom:organization"
                  type="text"
                  maxLength={320}
                />
              )}
            </Form.Item>
          )}

          {organizationName && (
            <Form.Item>
              <Input placeholder={organizationName} maxLength={320} disabled />
            </Form.Item>
          )}

          <Form.Item>
            <Checkbox onChange={this.handleChange}>
              {t('v3:sharelook_agree')}{' '}
              <Link target="_blank" to="/pages/terms-conditions">
                {t('v3:terms_condition')}
              </Link>{' '}
              {t('v3:and')}{' '}
              <Link target="_blank" to="/pages/privacy">
                {t('v3:privacy_policy')}
              </Link>
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loading}
              disabled={!checked}
            >
              {t('labels:signup')}
            </Button>
          </Form.Item>
        </Form>
      </Fragment>
    )
  }
}

export default Form.create()(withTranslation('signup')(RegisterForm))
