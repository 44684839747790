import React, { useEffect, useRef } from 'react'
import { AudioPlayerStyle } from './styled'

const activeAudioElements = new Set()

const AudioPlayer = ({ autoPlay, src, isCourse = false }) => {
  const audioRef = useRef(null)

  useEffect(() => {
    const audio = audioRef.current

    if (src && audio) {
      audio.load()
      activeAudioElements.add(audio)

      if (autoPlay) {
        const playPromise = audio.play()
        if (playPromise && typeof playPromise.then === 'function') {
          playPromise.catch(function(error) {
            console.log('Autoplay prevented:', error)
          })
        }
      }
    }

    return function cleanup() {
      if (audio) {
        audio.pause()
        audio.currentTime = 0
        activeAudioElements.delete(audio)
      }
    }
  }, [src, autoPlay])

  useEffect(() => {
    return function cleanupAll() {
      activeAudioElements.forEach(function(audio) {
        audio.pause()
        audio.currentTime = 0
      })
      activeAudioElements.clear()
    }
  }, [])

  return (
    <AudioPlayerStyle height={isCourse ? '100%' : '40px'}>
      <audio
        ref={audioRef}
        controls
        autoPlay={autoPlay}
        controlsList="nodownload"
        preload="none"
      >
        <source src={src} type="audio/mp3" />
        Your browser does not support the audio tag.
      </audio>
    </AudioPlayerStyle>
  )
}

export default AudioPlayer
