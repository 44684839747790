// Custom size list for font size selection
const sizeList = [
  '8px',
  '10px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '24px',
  '36px',
  '48px'
]

// Quill toolbar configuration
const toolbar = {
  container: [
    [{ size: sizeList }],
    [{ header: [1, 2, 3, false] }],

    ['bold', 'italic', 'underline', 'strike'], // Basic text formatting
    [{ script: 'sub' }, { script: 'super' }], // Subscript / Superscript
    [{ color: [] }, { background: [] }], // Text & background color

    [{ align: [] }], // Text alignment
    [{ list: 'ordered' }, { list: 'bullet' }], // Ordered/Unordered lists
    [{ indent: '-1' }, { indent: '+1' }], // Indentation

    ['blockquote', 'code-block'], // Blockquote & Code block
    ['link'] // Media embedding
  ],
  handlers: {
    color: function(value) {
      let quill = this.quill
      if (value === 'color-picker') {
        let picker = document.getElementById('color-picker')
        if (!picker) {
          picker = document.createElement('input')
          picker.id = 'color-picker'
          picker.type = 'color'
          picker.addEventListener('change', function() {
            quill.format('color', picker.value)
          })

          let colorItems = document.querySelectorAll(
            `[data-value='color-picker']`
          )
          let colorItem =
            colorItems &&
            Object.values(colorItems).find(item =>
              item.classList.contains('ql-picker-item')
            )

          if (colorItem) {
            colorItem.appendChild(picker)
          }
        }
        picker.click()
      } else {
        quill.format('color', value)
      }
    }
  }
}

export { sizeList, toolbar }
