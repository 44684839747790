import React, { useState } from 'react'
import { Upload, Button, Input, Card, Typography, Row, Col } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { ImagePreview } from '../../../../../components'

const { TextArea } = Input
const { Title, Text } = Typography

const ImageUploader = ({ onImageUpload, onGenerateCharacter }) => {
  const [previewImage, setPreviewImage] = useState(null)
  const [textPrompt, setTextPrompt] = useState('')

  const handleImageUpload = file => {
    const reader = new FileReader()
    reader.onload = e => setPreviewImage(e.target.result)
    reader.readAsDataURL(file)
    onImageUpload(file) // Pass file to parent
    return false // Prevent auto upload
  }

  const handleGenerateClick = () => {
    onGenerateCharacter(textPrompt)
  }

  return (
    <Card
      bordered
      style={{
        width: '100%',
        padding: '24px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
      }}
    >
      <Title level={3} style={{ textAlign: 'center', marginBottom: '16px' }}>
        Upload an Image Reference
      </Title>
      <Text
        style={{ display: 'block', textAlign: 'center', marginBottom: '24px' }}
      >
        Add an image and optionally a text prompt to generate your character.
      </Text>

      <Row
        style={{ display: 'flex', justifyContent: 'center', margin: '24px 0' }}
      >
        <Upload
          beforeUpload={handleImageUpload}
          showUploadList={false}
          accept="image/*"
        >
          <Button
            icon={<UploadOutlined />}
            type="primary"
            style={{ fontWeight: 'bold' }}
          >
            Upload Image
          </Button>
        </Upload>
      </Row>
      {previewImage && (
        <Card>
          <Row gutter={16} style={{ marginBottom: '24px' }}>
            <Col xs={24} sm={12}>
              <div
                style={{
                  height: '200px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px dashed #d9d9d9',
                  borderRadius: '4px',
                  overflow: 'hidden'
                }}
              >
                <ImagePreview
                  src={previewImage}
                  alt="Character Preview"
                  style={{
                    maxWidth: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <TextArea
                value={textPrompt}
                onChange={e => setTextPrompt(e.target.value)}
                placeholder="Enter a text prompt to describe your character"
                rows={6}
                style={{
                  height: '200px',
                  borderRadius: '4px',
                  boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.1)',
                  resize: 'none'
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                disabled={!textPrompt}
                onClick={handleGenerateClick}
                style={{
                  padding: '8px 32px',
                  fontWeight: 'bold'
                }}
              >
                Generate Character
              </Button>
            </Col>
          </Row>
        </Card>
      )}
    </Card>
  )
}

export default ImageUploader
