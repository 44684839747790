export const scenes = [
  {
    id: 1,
    title: 'Scene 01',
    description: 'Big city night scene',
    settings: {
      location: "Big city with a reporter's van on the street",
      duration: 5,
      fps: 24,
      resolution: '1080p',
      musicIntensity: 50
    },
    shots: [
      {
        id: 1,
        src: 'https://www.w3schools.com/html/mov_bbb.mp4',
        prompt: 'A news reporter reporting on latest news',
        shotType: 'Medium-Shot',
        clipDuration: 3
      },
      {
        id: 2,
        src: 'https://www.w3schools.com/html/movie.mp4',
        prompt: 'A big screen showing news',
        shotType: 'Close-Up',
        clipDuration: 2
      },
      {
        id: 3,
        src: '', // No video yet
        prompt: 'Loads of police cyberpunk robots running on the street',
        shotType: '',
        clipDuration: 4
      }
    ]
  },
  {
    id: 2,
    title: 'Scene 02',
    description: 'Rural morning tranquility',
    settings: {
      location: 'Countryside with morning mist',
      duration: 6,
      fps: 30,
      resolution: '720p',
      musicIntensity: 30
    },
    shots: [
      {
        id: 1,
        src: 'https://example.com/rural_morning.mp4',
        prompt: 'Wide shot of a quiet rural landscape',
        shotType: 'Wide-Shot',
        clipDuration: 4
      },
      {
        id: 2,
        src: 'https://example.com/bird.mp4',
        prompt: 'Bird singing in a tree',
        shotType: 'Close-Up',
        clipDuration: 2
      }
    ]
  },
  {
    id: 3,
    title: 'Scene 03',
    description: 'Urban rush hour',
    settings: {
      location: 'Busy city intersection',
      duration: 8,
      fps: 24,
      resolution: '1080p',
      musicIntensity: 75
    },
    shots: [
      {
        id: 1,
        src: 'https://example.com/traffic.mp4',
        prompt: 'Traffic congestion at peak hours',
        shotType: 'Medium-Shot',
        clipDuration: 5
      },
      {
        id: 2,
        src: 'https://example.com/metro.mp4',
        prompt: 'People rushing into subway',
        shotType: 'Medium-Shot',
        clipDuration: 3
      }
    ]
  },
  {
    id: 4,
    title: 'Scene 04',
    description: 'Beach sunset',
    settings: {
      location: 'Tropical beach at sunset',
      duration: 7,
      fps: 24,
      resolution: '4K',
      musicIntensity: 20
    },
    shots: [
      {
        id: 1,
        src: 'https://example.com/beach_sunset.mp4',
        prompt: 'Sun setting over the ocean',
        shotType: 'Wide-Shot',
        clipDuration: 4
      },
      {
        id: 2,
        src: 'https://example.com/waves.mp4',
        prompt: 'Waves lapping at the shore',
        shotType: 'Close-Up',
        clipDuration: 3
      }
    ]
  }
]

export const transitions = [
  { id: 'fade', name: 'Fade', icon: 'eye' },
  { id: 'slide', name: 'Slide', icon: 'arrow-right' },
  { id: 'dissolve', name: 'Dissolve', icon: 'swap' },
  { id: 'zoom-in', name: 'Zoom In', icon: 'zoom-in' },
  { id: 'zoom-out', name: 'Zoom Out', icon: 'zoom-out' },
  { id: 'wipe-left', name: 'Wipe Left', icon: 'arrow-left' },
  { id: 'wipe-right', name: 'Wipe Right', icon: 'arrow-right' },
  { id: 'wipe-up', name: 'Wipe Up', icon: 'arrow-up' },
  { id: 'wipe-down', name: 'Wipe Down', icon: 'arrow-down' },
  { id: 'flip-horizontal', name: 'Flip Horizontal', icon: 'swap' },
  { id: 'flip-vertical', name: 'Flip Vertical', icon: 'retweet' },
  { id: 'roll', name: 'Roll', icon: 'sync' },
  { id: 'circle-reveal', name: 'Circle Reveal', icon: 'play-circle' }
]
