import React from 'react'
import { Button, Icon } from 'antd'

const CustomPagination = ({
  currentPage,
  totalPages,
  onPageChange,
  loading
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 0'
      }}
    >
      <Button
        style={{ borderRadius: '8px' }}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1 || loading}
      >
        <Icon type="left" />
        {' Previous'}
      </Button>
      <span style={{ margin: '0 15px', color: '#000' }}>
        {`Page ${currentPage} of ${totalPages}`}
      </span>
      <Button
        style={{ borderRadius: '8px' }}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages || loading}
      >
        {'Next '} <Icon type="right" />
      </Button>
    </div>
  )
}

export default CustomPagination
