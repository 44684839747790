import React, { useState, useCallback, useEffect } from 'react'
import { Upload, Icon, message, Modal, Button, Input, Radio } from 'antd'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import './UploadDocument.scss'
import StoryCreationForm from './StoryCreationForm'
import StepTitle from '../StepTitle/StepTitle'

const UploadDocument = ({
  formData,
  onValidationChange,
  setFormData,
  onProceed
}) => {
  const [fileList, setFileList] = useState([])
  const [isStoryFormVisible, setIsStoryFormVisible] = useState(false)
  const [showStoryText, setShowStoryText] = useState(false)
  const [isDragActive, setIsDragActive] = useState(false)
  const [inputMethod, setInputMethod] = useState('upload')
  const [directText, setDirectText] = useState('')
  const [originalText, setOriginalText] = useState('')
  const [hasChanges, setHasChanges] = useState(false)

  // Initialize component state from formData
  useEffect(() => {
    const { file, voiceoverText } = formData
    if (!file && !voiceoverText) return

    // Only update if values are different
    if (file && (!fileList.length || fileList[0].uid !== file.uid)) {
      setFileList([file])
    }
    if (voiceoverText && voiceoverText !== directText) {
      setDirectText(voiceoverText)
      setOriginalText(voiceoverText)
    }
    setInputMethod(file ? 'upload' : 'text')
  }, [formData])

  // Track text changes
  useEffect(() => {
    setHasChanges(directText.trim() !== originalText.trim())
  }, [directText, originalText])

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length > 0) {
        const newFile = {
          uid: acceptedFiles[0].name,
          name: acceptedFiles[0].name,
          status: 'done',
          url: URL.createObjectURL(acceptedFiles[0])
        }

        setFileList([newFile])
        setIsStoryFormVisible(true)
        // Update formData directly here instead of in a separate effect
        setFormData(prev => ({ ...prev, file: newFile }))
      }
    },
    [setFormData]
  )

  const handleRemove = file => {
    setFileList([])
    setFormData(prev => ({ ...prev, file: null }))

    if (!formData.voiceoverText) {
      onValidationChange(prev => ({ ...prev, uploadDocument: false }))
    }
  }

  const handleCloseStoryForm = () => {
    setIsStoryFormVisible(false)
  }

  const handleAddStory = story => {
    setIsStoryFormVisible(false)
    setOriginalText(story)
    setDirectText(story)
    setFormData(prev => ({ ...prev, voiceoverText: story }))
    onValidationChange(prev => ({ ...prev, uploadDocument: true }))
  }

  const handleDirectTextChange = text => {
    setDirectText(text)
  }

  const handleConfirmText = () => {
    if (!directText.trim()) {
      message.error('Please enter your script text before confirming')
      return
    }

    if (!hasChanges) {
      onProceed()
      return
    }

    setFormData(prev => ({ ...prev, voiceoverText: directText }))
    setOriginalText(directText)
    onValidationChange(prev => ({ ...prev, uploadDocument: true }))
    message.success('Script text has been saved')
  }

  const openPDF = file => {
    if (file && file.url) {
      window.open(file.url, '_blank')
    } else {
      console.error('File URL not available')
    }
  }

  const getButtonLabel = () => {
    if (!directText.trim()) return 'Confirm Script Text'
    return hasChanges ? 'Confirm Script Text' : 'Proceed to Voiceover'
  }

  return (
    <div className="upload-document">
      <StepTitle
        iconType="file-text"
        title="Upload Document"
        description="Please upload a PDF document with your video script or provide your
          script text directly."
      />

      <div className="upload-document__input-selector">
        <Radio.Group
          value={inputMethod}
          onChange={e => setInputMethod(e.target.value)}
          buttonStyle="solid"
          size="large"
          className="upload-document__input-selector-radio"
        >
          <Radio.Button value="upload">PDF Document</Radio.Button>
          <Radio.Button value="text">Direct Text Input</Radio.Button>
        </Radio.Group>
      </div>

      {inputMethod === 'upload' ? (
        <div>
          {fileList.length === 0 ? (
            <Dropzone
              className="upload-document__dropzone-container"
              onDrop={onDrop}
              accept="application/pdf"
              maxFiles={1}
              onDropRejected={() =>
                message.error('Only one PDF file is allowed.')
              }
              onDragEnter={() => setIsDragActive(true)}
              onDragLeave={() => setIsDragActive(false)}
            >
              {({ getRootProps, getInputProps, isDragActive: isActive }) => (
                <div
                  className={`upload-document__dropzone ${
                    isActive ? 'active' : ''
                  }`}
                >
                  <div className="upload-document__upload-content">
                    <Icon type="file-pdf" />
                    <p className="upload-document__text">
                      {isActive
                        ? 'Drop your PDF here'
                        : 'Drag & drop your document here, or click to select'}
                    </p>
                    <p className="upload-document__hint">
                      Supported format: PDF (max 10MB)
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
          ) : (
            <div className="upload-document__file-preview">
              <div className="file-item">
                <Icon type="file-pdf" className="file-icon" />
                <span
                  className="file-name"
                  onClick={() => openPDF(fileList[0])}
                >
                  {fileList[0].name}
                </span>
                <Button
                  type="link"
                  className="remove-button"
                  onClick={() => handleRemove(fileList[0])}
                >
                  <Icon type="delete" />
                </Button>
              </div>
            </div>
          )}

          {fileList.length > 0 && (
            <Button
              className="upload-document__button"
              type="primary"
              onClick={() => setIsStoryFormVisible(true)}
            >
              Generate Script
            </Button>
          )}
        </div>
      ) : (
        <div className="upload-document__text-input">
          <Input.TextArea
            value={directText}
            onChange={e => handleDirectTextChange(e.target.value)}
            placeholder="Please enter your video script text here"
            autoSize={{ minRows: 8, maxRows: 20 }}
            className="upload-document__textarea"
          />
          <div className="upload-document__text-actions">
            <Button
              type="primary"
              onClick={handleConfirmText}
              className="upload-document__confirm-button"
              disabled={!directText.trim()}
            >
              {getButtonLabel()}
            </Button>
          </div>
        </div>
      )}

      {formData.voiceoverText && formData.file && inputMethod === 'upload' && (
        <Button
          className="upload-document__button"
          type="primary"
          onClick={() => setShowStoryText(true)}
        >
          Edit Script
        </Button>
      )}

      {isStoryFormVisible && (
        <StoryCreationForm
          uploadedFile={fileList}
          onCancel={handleCloseStoryForm}
          onAddStory={handleAddStory}
        />
      )}

      {showStoryText && (
        <Modal
          title="Script Editor"
          visible={true}
          footer={null}
          onCancel={() => setShowStoryText(false)}
        >
          <Input.TextArea
            value={formData.voiceoverText}
            onChange={e =>
              setFormData(prev => ({ ...prev, voiceoverText: e.target.value }))
            }
            autoSize={{ minRows: 6 }}
          />
        </Modal>
      )}
    </div>
  )
}

UploadDocument.propTypes = {
  onValidationChange: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired
}

export default UploadDocument
