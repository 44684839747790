import axios from 'axios'
import { config } from '../../../../../../../../../../constants'

export const fetchVoices = async () => {
  try {
    const response = await axios.get('https://api.elevenlabs.io/v1/voices', {
      headers: {
        Accept: 'application/json',
        'xi-api-key': config.elevenLabsApiKey
      }
    })
    return response.data.voices.filter(voice => voice.category === 'premade')
  } catch (error) {
    throw new Error('Failed to fetch voices')
  }
}

export const generateVoiceover = async (text, selectedVoice, settings) => {
  try {
    const response = await axios.post(
      `https://api.elevenlabs.io/v1/text-to-speech/${selectedVoice.voice_id}`,
      {
        text,
        voice_settings: settings
      },
      {
        headers: {
          Accept: 'audio/mpeg',
          'xi-api-key': config.elevenLabsApiKey,
          'Content-Type': 'application/json'
        },
        responseType: 'blob'
      }
    )
    return URL.createObjectURL(response.data)
  } catch (error) {
    throw new Error('Failed to generate voiceover')
  }
}

export const defaultSettings = {
  model: 'eleven_multilingual_v2',
  stability: 0.5,
  similarity_boost: 0.75,
  style: 0.5,
  use_speaker_boost: true
}

export const filterVoices = (voices, searchQuery) => {
  return voices.filter(voice => {
    const matchesName = voice.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
    const matchesLabels = Object.values(voice.labels || {}).some(value =>
      value.toLowerCase().includes(searchQuery.toLowerCase())
    )
    return matchesName || matchesLabels
  })
}

export const getLabelIcon = labelType => {
  const iconMap = {
    accent: 'global',
    description: 'info-circle',
    age: 'user',
    gender: 'team',
    use_case: 'tag'
  }
  return iconMap[labelType] || 'tag'
}

export const createAudioPlayer = (previewUrl, onEnd) => {
  const audio = new Audio(previewUrl)
  audio.onended = onEnd
  return audio
}

export class AudioManager {
  constructor(onPlayingStateChange) {
    this.currentAudio = null
    this.playingVoiceId = null
    this.onPlayingStateChange = onPlayingStateChange
  }

  play(voiceId, previewUrl) {
    this.stop()

    const audio = new Audio(previewUrl)
    audio.onended = () => {
      this.currentAudio = null
      this.playingVoiceId = null
      if (this.onPlayingStateChange) {
        this.onPlayingStateChange(null)
      }
    }

    this.currentAudio = audio
    this.playingVoiceId = voiceId
    if (this.onPlayingStateChange) {
      this.onPlayingStateChange(voiceId)
    }
    audio.play()
  }

  stop() {
    if (this.currentAudio) {
      this.currentAudio.pause()
      this.currentAudio.currentTime = 0
      this.currentAudio = null
      this.playingVoiceId = null
      if (this.onPlayingStateChange) {
        this.onPlayingStateChange(null)
      }
    }
  }

  isPlaying(voiceId) {
    return this.playingVoiceId === voiceId
  }

  stopAll() {
    const audioElements = document.querySelectorAll('audio')
    audioElements.forEach(audio => {
      audio.pause()
      audio.currentTime = 0
    })
    this.currentAudio = null
    this.playingVoiceId = null
    if (this.onPlayingStateChange) {
      this.onPlayingStateChange(null)
    }
  }
}
