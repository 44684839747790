// Toolbar.js
import React, { useState, useRef, useEffect } from 'react'
import { Icon, Switch, Tooltip, message, Popover, Modal, Button } from 'antd'
import { builder } from '../../../constants'
import nanoid from 'nanoid'
import './Toolbar.scss'
import className from 'classnames'
import { withTranslation } from 'react-i18next'
import customImages from './Images/index'
import ImagePreview from '../../ImagePreview'
import BackgroundImageSettings from './components/BackgroundImageSettings'
import BackgroundColorSettings from './components/BackgroundColorSettings'

const { toolbar, types } = builder

const Toolbar = ({
  handleChangeBackground,
  handleData,
  handleParams,
  pageStructure,
  mode,
  t,
  isLandScape,
  enableGrid,
  currentPage,
  pages,
  pages_landscape,
  handleLessonInput,
  onAutoBackgroundChange,
  lessonBckgrndImg,
  lessonBckgrndImgId,
  handleShowAnnotationImageModal,
  handleShowAnnotationTextModal,
  handleShowAnnotationVideoModal,
  handleShowAnnotationAudioModal,
  handleShowAnnotationModal,
  handleShowAudioModal,
  handleShowImageModal,
  handleShowVideoModal,
  handleShowTextModal,
  handleShowQuizModal,
  handleShowGrid
}) => {
  const [showOptions, setShowOptions] = useState(false)
  const [showImageModal, setShowImageModal] = useState(false)
  const [showColorModal, setShowColorModal] = useState(false)
  const [selectedColor, setSelectedColor] = useState(null)
  const [tempColor, setTempColor] = useState('#ffffff')

  useEffect(() => {
    const backgroundColor = pageStructure.find(
      node =>
        node.type === types.BACKGROUND_COLOR && node.params.content !== null
    )

    setSelectedColor(backgroundColor ? backgroundColor.params.content : null)
    setTempColor(backgroundColor ? backgroundColor.params.content : null)
  }, [pageStructure])

  const handleColorConfirm = () => {
    if (handleChangeBackground) {
      handleChangeBackground('color', tempColor)
      setSelectedColor(tempColor)
    }
    setShowColorModal(false)
  }

  const removeBackgroundElement = type => {
    if (handleData) {
      const data = pageStructure.filter(node => node.type !== type)
      handleData(data)
    }
    setShowImageModal(false)
    setShowColorModal(false)
  }

  const OptionsContent = ({ disabled }) => {
    if (disabled) {
      return (
        <div className="background-options">
          Background Change is not allowed when "Auto Background" is enabled.
          Please disable "Auto Background" to enable this feature.
        </div>
      )
    }

    return (
      <div className="background-options">
        <div
          className="background-option"
          onClick={() => {
            const hasBackgroundImage = pageStructure.find(
              node => node.type === types.BACKGROUND_IMAGE
            )
            if (hasBackgroundImage) {
              setShowImageModal(true)
            } else {
              handleChangeBackground('image')
            }
            setShowOptions(false)
          }}
        >
          <Icon type="picture" />
          <span>{t('v4:bg_image')}</span>
        </div>
        <div
          className="background-option"
          onClick={() => {
            setShowOptions(false)
            setShowColorModal(true)
          }}
        >
          <Icon type="bg-colors" />
          <span>{t('v4:bg_color')}</span>
        </div>
      </div>
    )
  }

  const backgroundElement = pageStructure.find(node =>
    [
      types.BACKGROUND_IMAGE,
      types.BACKGROUND_COLOR
      // types.BACKGROUND_IMAGE_AUTO
    ].includes(node.type)
  )

  const BackgroundIndicator = () => {
    const isBackgroundImage =
      backgroundElement && backgroundElement.type === types.BACKGROUND_IMAGE
    const isBackgroundColor =
      backgroundElement && backgroundElement.type === types.BACKGROUND_COLOR
    const showIndicator = isBackgroundImage || isBackgroundColor
    return (
      <div
        className="toolbar__item"
        onClick={() => {
          if (backgroundElement) {
            if (backgroundElement.type === types.BACKGROUND_IMAGE) {
              setShowImageModal(true)
            } else if (backgroundElement.type === types.BACKGROUND_COLOR) {
              setShowColorModal(true)
            }
          } else {
            setShowOptions(visible => !visible)
          }
        }}
      >
        {showIndicator ? (
          <div className="toolbar__item-icon background-preview">
            {backgroundElement.type === types.BACKGROUND_IMAGE ? (
              <div
                className="image-preview"
                style={{
                  backgroundImage: `url(${backgroundElement.params.content})`,
                  borderRadius: '50%',
                  backgroundSize: 'cover',
                  opacity: backgroundElement.params.opacity || 1
                }}
              />
            ) : (
              <div
                className="color-preview"
                style={{
                  backgroundColor: selectedColor,
                  borderRadius: '50%'
                }}
              />
            )}
          </div>
        ) : (
          <div className="toolbar__item-icon">
            {getToolbarImage(types.BACKGROUND_IMAGE)}
          </div>
        )}
        <div className="toolbar__item-text">{t('v4:background')}</div>
      </div>
    )
  }

  const addElement = type => {
    if (handleData) {
      const width = type === types.TEXT ? 450 : 400

      const data = {
        uid: nanoid(8),
        type: type,
        params: {
          content: '',
          width: width,
          height: 200,
          x: 0,
          y: 0
        }
      }

      handleData(data, true)
    }
  }

  const handleToolClick = ({ type }) => {
    const pageHasScenario = pageStructure.some(
      node => node.type === types.SCENARIO
    )

    if (pageHasScenario) {
      message.error(
        'You cannot add other element in a scenario page. Please try other pages or remove the scenario from the page first.',
        10
      )
      return
    }

    if (mode === types.ANNOTATION) {
      if (type === types.TEXT) {
        handleShowAnnotationTextModal()
      } else if (type === types.IMAGE) {
        handleShowAnnotationImageModal()
      } else if (type === types.VIDEO) {
        handleShowAnnotationVideoModal()
      } else if (type === types.AUDIO) {
        handleShowAnnotationAudioModal()
      }
    } else {
      if (type === types.AUDIO) {
        handleShowAudioModal()
      } else if (type === types.IMAGE) {
        handleShowImageModal(false)
      } else if (type === types.AR_IMAGE) {
        handleShowImageModal(true)
      } else if (type === types.VIDEO) {
        handleShowVideoModal()
      } else if (type === types.TEXT) {
        handleShowTextModal()
      } else if (type === types.ANNOTATION) {
        handleShowAnnotationModal()
      } else if (type === types.QUIZ) {
        handleShowQuizModal()
      } else if (type === types.GRID) {
        handleShowGrid()
      } else {
        addElement(type)
      }
    }
  }

  const onChangeAutoBgChange = () => {
    const pagesTemp = [...pages]
    const pagesLandscapeTemp = [...pages_landscape]
    const pageStructureTemp = [...pageStructure]
    const indexVal = pageStructure.findIndex(
      item => item.type === 'BACKGROUND_IMAGE_AUTO'
    )

    if (lessonBckgrndImg !== null && indexVal === -1) {
      const dataLND = {
        uid: nanoid(8),
        type: 'BACKGROUND_IMAGE_AUTO',
        auto: true,
        params: { content: lessonBckgrndImg, content_id: lessonBckgrndImgId }
      }
      pagesTemp[currentPage - 1].data.push(dataLND)
      pagesLandscapeTemp[currentPage - 1].data.push(dataLND)
      handleLessonInput('pages', pagesTemp)
      handleLessonInput('pages_landscape', pagesLandscapeTemp)
      onAutoBackgroundChange()
    }

    if (
      indexVal !== -1 &&
      pageStructure.length > 0 &&
      pageStructure[indexVal].hasOwnProperty('auto')
    ) {
      if (pageStructure[indexVal].auto === false) {
        pageStructureTemp[indexVal].auto = true
      } else {
        pageStructureTemp[indexVal].auto = false
      }
      pagesTemp[currentPage - 1].data = pageStructureTemp
      pagesLandscapeTemp[currentPage - 1].data = pageStructureTemp
      handleLessonInput('pages', pagesTemp)
      handleLessonInput('pages_landscape', pagesLandscapeTemp)
      onAutoBackgroundChange()
    }
  }

  const getToolbarImage = type => {
    switch (type) {
      case types.ANNOTATION:
        return <img src={customImages.Annotation} alt="Annotation" />
      case types.AR_IMAGE:
        return <img src={customImages.ArImage} alt="AR" />
      case types.IMAGE:
        return <img src={customImages.Image} alt="Image" />
      case types.AUDIO:
        return <img src={customImages.Audio} alt="Audio" />
      case types.BACKGROUND_IMAGE:
        return <img src={customImages.Background} alt="Background" />
      case types.GRID:
        return <img src={customImages.Grid} alt="Grid" />
      case types.QUIZ:
        return <img src={customImages.Quiz} alt="Quiz" />
      case types.TEXT:
        return <img src={customImages.Text} alt="Text" />
      case types.VIDEO:
        return <img src={customImages.Video} alt="Video" />
      default:
        return null
    }
  }

  const items = toolbar.filter(item => item.mode.includes(mode))
  const classNames = className('toolbar__list', {
    toolbar__list__annotation: mode === types.ANNOTATION
  })

  const indexVal = pageStructure.findIndex(
    item => item.type === 'BACKGROUND_IMAGE_AUTO' && item.auto === true
  )

  const isAutoBackgroundEnabled =
    !pageStructure.find(
      node =>
        node.type === types.BACKGROUND_IMAGE ||
        node.type === types.BACKGROUND_COLOR
    ) &&
    pageStructure.length > 0 &&
    indexVal !== -1 &&
    pageStructure[indexVal].hasOwnProperty('auto') &&
    pageStructure[indexVal].auto === true

  const handleAutoBackgroundChange = () => {
    if (backgroundElement) {
      message.error(
        'Background is already selected. Remove the current background first to enable Auto Background',
        10
      )
    } else {
      onChangeAutoBgChange()
    }
  }

  return (
    <div className={mode === types.ANNOTATION ? '' : 'toolbar'}>
      <div className="toolbar__inner">
        <ul className={classNames}>
          {items.map((item, index) => {
            if (
              item.type === types.BACKGROUND_IMAGE ||
              item.type === types.BACKGROUND_COLOR
            ) {
              return (
                <li key={index}>
                  <Popover
                    content={
                      <OptionsContent disabled={isAutoBackgroundEnabled} />
                    }
                    visible={showOptions && !backgroundElement}
                    onVisibleChange={visible => setShowOptions(visible)}
                    trigger="click"
                    placement="bottom"
                  >
                    <BackgroundIndicator />
                  </Popover>
                </li>
              )
            }
            return (
              <li key={index}>
                {!isLandScape && (
                  // <Tooltip title={item.name}>
                  <div
                    className={`toolbar__item ${
                      enableGrid && item.type === 'GRID' ? 'active_grid' : ''
                    }`}
                    onClick={() => handleToolClick(item)}
                  >
                    <div className="toolbar__item-icon">
                      {getToolbarImage(item.type) || <Icon type={item.icon} />}
                    </div>
                    <div className="toolbar__item-text">{item.name}</div>
                  </div>
                  // </Tooltip>
                )}
              </li>
            )
          })}
        </ul>
        <BackgroundImageSettings
          visible={showImageModal}
          onCancel={() => setShowImageModal(false)}
          onRemove={() =>
            removeBackgroundElement(backgroundElement && backgroundElement.type)
          }
          onChangeImage={() => {
            handleChangeBackground('image')
            setShowImageModal(false)
          }}
          backgroundElement={backgroundElement}
          handleParams={handleParams}
          pageStructure={pageStructure}
        />
        <BackgroundColorSettings
          visible={showColorModal}
          onCancel={() => setShowColorModal(false)}
          onRemove={() => removeBackgroundElement(types.BACKGROUND_COLOR)}
          selectedColor={selectedColor}
          tempColor={tempColor}
          setTempColor={setTempColor}
          onConfirm={handleColorConfirm}
        />
        {!pageStructure.find(node => node.type === types.BACKGROUND_IMAGE) && (
          <div className="toolbar__autobg">
            <p>{t('auto_background')}</p>
            <Switch
              checked={isAutoBackgroundEnabled}
              onChange={handleAutoBackgroundChange}
            />
          </div>
        )}{' '}
      </div>
    </div>
  )
}

export default withTranslation('v2')(Toolbar)
