import React, { Component, Fragment } from 'react'
import { Form, Input, Button, Alert } from 'antd'
import history from '../../../../../history'
import { withTranslation } from 'react-i18next'

class LoginForm extends Component {
  state = {
    loading: false,
    error: {
      status: false,
      message: null
    }
  }

  setError = payload => {
    this.setState({
      error: payload
    })
  }

  setLoading = value => {
    this.setState({
      loading: value
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { isMarketplace } = this.props

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setError({
          status: false,
          message: null
        })

        this.setLoading(true)

        this.props
          .signIn(values.username, values.password, isMarketplace)
          .then(() => {
            history.push('/')
          })
          .catch(error => {
            console.log(error)
            if (error.name === 'UserNotConfirmedException') {
              history.push('/auth/signup', {
                username: values.username,
                isUserNotConfirmed: true
              })
            } else if (error.name === 'InvalidParameterException') {
              error.message = 'The input is not valid email'
            }
            this.setError({
              status: true,
              message:
                error.message === 'User does not exist.'
                  ? 'Incorrect username or password.'
                  : error.message
            })

            this.setLoading(false)
          })
      }
    })
  }

  validateUsername = (rule, value, callback) => {
    const { t } = this.props

    if (value) {
      if (value.indexOf(' ') >= 0) callback(t('v3:error_username_space'))
      callback()
    } else {
      callback(t('errors:username') + ' / ' + t('labels:email'))
    }
  }

  render() {
    const { error, loading } = this.state
    const {
      form: { getFieldDecorator },
      t
    } = this.props

    return (
      <Fragment>
        {error.status && (
          <div style={{ marginBottom: '2.4rem' }}>
            <Alert message={error.message} type="error" />
          </div>
        )}
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [
                {
                  required: true,
                  message: t('errors:username') + ' / ' + t('labels:email')
                }
              ]
            })(
              <Input
                placeholder={t('labels:username') + ' / ' + t('labels:email')}
                name="username"
                maxLength={320}
              />
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: t('errors:password')
                }
              ]
            })(
              <Input.Password
                placeholder={t('labels:password')}
                name="password"
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              {t('labels:signin')}
            </Button>
          </Form.Item>
        </Form>
      </Fragment>
    )
  }
}

export default Form.create()(withTranslation()(LoginForm))
