import React from 'react'
import { Row, Col, Button } from 'antd'
import CharacterCard from './CharacterCard'

const ExistingCharacters = ({
  characters,
  selectedCharacter,
  onCharacterSelect,
  onSelectConfirm
}) => (
  <div className="existing-characters">
    <h3>Select from Existing Characters</h3>
    <Row gutter={[16, 16]}>
      {characters.map(character => (
        <Col key={character.id} xs={24} sm={12} md={8}>
          <CharacterCard
            character={character}
            isSelected={
              selectedCharacter && selectedCharacter.id === character.id
            }
            onClick={onCharacterSelect}
          />
        </Col>
      ))}
    </Row>

    {characters.length > 0 && (
      <div className="action-buttons" style={{ marginTop: '24px' }}>
        <Button onClick={onSelectConfirm} type="primary" size="large">
          Select Character
        </Button>
      </div>
    )}
  </div>
)

export default ExistingCharacters
