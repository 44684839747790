import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Spinner, Modal, RenderArrows } from '../../../../../components'
import Template from './Template'
import { Icon, message, Radio, Tooltip } from 'antd'
import { ModalInner, Footer, Save } from './styled'
import {
  HeadWrap,
  ZoomBtn,
  Btn,
  LandscapePageContent,
  LandscapePageWrapper,
  ZoomBtnGroup
} from '../../styled'
import './styles.scss'
import api from '../../../../../services/api'
import { useTranslation } from 'react-i18next'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import CustomPagination from '../../../../../components/CustomPagination/CustomPagination'

const ORIGINAL_WIDTH = 1023
const ORIGINAL_HEIGHT = 552

const ModalLessonLandscape = ({
  course,
  activeLessonId,
  lesson,
  toggleModal,
  modalVisible,
  userToken,
  enrollCourse,
  openNextLesson
}) => {
  const { t } = useTranslation('coursepage')
  const fullscreenRef = useRef(null)
  const modalInnerRef = useRef(null)
  const pageContentRef = useRef(null)
  const transformComponentRef = useRef(null)

  const lessons = course.courses_lessons || []
  const enrollment = course.enrollment || {}
  const lessonItem = lessons.find(i => i.id === activeLessonId)
  const uids =
    lessonItem && lessonItem.info && lessonItem.info.pages_uids
      ? lessonItem.info.pages_uids
      : []
  const completedUids =
    enrollment.progress && enrollment.progress[lessonItem.id]
      ? enrollment.progress[lessonItem.id]
      : []
  const initialIndex = uids.findIndex(uid => !completedUids.includes(uid))

  const [currentPage, setCurrentPage] = useState(
    initialIndex < 1 ? 1 : initialIndex
  )
  const [isMoveable, setIsMoveable] = useState(false)
  const [isGoToNextPageDisabled, setIsGoToNextPageDisabled] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [scaleFactor, setScaleFactor] = useState(1)
  const [shouldResetTransform, setShouldResetTransform] = useState(false)

  const calculateScaleFactor = useCallback(() => {
    if (isFullscreen) {
      const screenWidth = window.innerWidth
      const screenHeight = window.innerHeight
      const widthRatio = screenWidth / ORIGINAL_WIDTH
      const heightRatio = screenHeight / ORIGINAL_HEIGHT
      return Math.min(widthRatio, heightRatio)
    }
    return 1
  }, [isFullscreen])

  const doResize = useCallback(() => {
    const newScaleFactor = calculateScaleFactor()
    setScaleFactor(newScaleFactor)
  }, [calculateScaleFactor])

  useEffect(() => {
    doResize()
    window.addEventListener('resize', doResize)
    document.addEventListener('fullscreenchange', handleFullscreenChange)
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange)
    document.addEventListener('mozfullscreenchange', handleFullscreenChange)
    document.addEventListener('MSFullscreenChange', handleFullscreenChange)

    return () => {
      window.removeEventListener('resize', doResize)
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullscreenChange
      )
      document.removeEventListener(
        'mozfullscreenchange',
        handleFullscreenChange
      )
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange)
      postCheckOutData('willUnMount landscape')
    }
  }, [doResize])

  useEffect(() => {
    doResize()
    if (!isFullscreen) {
      setShouldResetTransform(true)
    }
  }, [isFullscreen])

  useEffect(() => {
    if (shouldResetTransform && transformComponentRef.current) {
      transformComponentRef.current.resetTransform()
      setScaleFactor(1)
      setShouldResetTransform(false)
    }
  }, [shouldResetTransform])

  const postCheckOutData = param => {
    const data = { user_token: userToken }
    api.courses
      .checkOutCourse(course.id, data)
      .then(({ data }) => {
        console.log('response log checkout', data)
      })
      .catch(e => {})
  }

  const changePage = page => {
    if (isGoToNextPageDisabled) {
      message.warning(t('v3:warning_complete_quiz'))
      return
    }

    if (page > currentPage + 1) {
      return
    }

    setCurrentPage(page)

    if (
      lesson.item &&
      lesson.item.pages_landscape &&
      lesson.item.pages_landscape[page - 1]
    ) {
      const { uid } = lesson.item.pages_landscape[page - 1]
      console.log('enroll course data check', course.id, lesson.item.id, uid)
      enrollCourse(course.id, lesson.item.id, uid)
    }
  }

  const handleOpenNextLesson = id => {
    postCheckOutData()
    setCurrentPage(1)
    openNextLesson(id)
  }

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      if (fullscreenRef.current.requestFullscreen) {
        fullscreenRef.current.requestFullscreen()
      } else if (fullscreenRef.current.mozRequestFullScreen) {
        fullscreenRef.current.mozRequestFullScreen()
      } else if (fullscreenRef.current.webkitRequestFullscreen) {
        fullscreenRef.current.webkitRequestFullscreen()
      } else if (fullscreenRef.current.msRequestFullscreen) {
        fullscreenRef.current.msRequestFullscreen()
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }
  }

  const handleFullscreenChange = () => {
    setIsFullscreen(!!document.fullscreenElement)
  }

  const FooterElement = () => {
    const isLastLesson = activeLessonId === lessons[lessons.length - 1].id
    const totalPages =
      lesson.item && lesson.item.pages_landscape
        ? lesson.item.pages_landscape.length
        : 0

    const isLastPage = currentPage === totalPages

    const nextLessonTitle = isLastLesson
      ? ''
      : lessons[lessons.findIndex(i => i.id === activeLessonId) + 1].title

    return (
      <Footer id="course_view_footer" isFullscreen={isFullscreen}>
        <Footer.Wrapp spaceBetween={isLastPage}>
          <div className="styled-pagination">
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage}
              loading={lesson.fetch}
            />
          </div>
          {isLastPage && (
            <>
              {isLastLesson ? (
                <Btn
                  type="primary"
                  className="rounded"
                  onClick={() => handleOpenNextLesson(lesson.item.id)}
                >
                  {t('buttons:close_lesson')}
                </Btn>
              ) : (
                <NextLessonButton
                  onClick={() => handleOpenNextLesson(lesson.item.id)}
                  lessonTitle={nextLessonTitle}
                />
              )}
            </>
          )}
        </Footer.Wrapp>
      </Footer>
    )
  }

  const NextLessonButton = ({ onClick, lessonTitle = '' }) => {
    return (
      <div className="next-lesson-button" onClick={onClick}>
        <div className="next-lesson-button__title">
          <span>Next Lesson</span>
          <h3>{lessonTitle}</h3>
        </div>
        <Icon type="right" />
      </div>
    )
  }

  const RenderZoomButtons = ({ zoomIn, zoomOut, resetTransform }) => {
    return (
      <ZoomBtnGroup>
        <Radio.Group>
          <Tooltip title={t('v4:zoom_in')}>
            <Radio.Button value="zoom-in" onClick={() => zoomIn()}>
              <Icon type="zoom-in" />
            </Radio.Button>
          </Tooltip>
          <Tooltip title={t('v4:zoom_out')}>
            <Radio.Button value="zoom-out" onClick={() => zoomOut()}>
              <Icon type="zoom-out" />
            </Radio.Button>
          </Tooltip>
          <Tooltip title={t('v4:zoom_reset')}>
            <Radio.Button
              value="zoom-reset"
              onClick={() => {
                resetTransform()
                setScaleFactor(1)
              }}
            >
              <Icon type="reload" />
            </Radio.Button>
          </Tooltip>
        </Radio.Group>
        <ZoomBtn icon="fullscreen" onClick={toggleFullscreen}>
          {isFullscreen ? t('v4:exit_fullscreen') : t('v4:fullscreen')}
        </ZoomBtn>
      </ZoomBtnGroup>
    )
  }

  const currentLessonTitle =
    lessons[lessons.findIndex(i => i.id === activeLessonId)].title

  console.log({
    title: course,
    currentLessonTitle
  })

  return (
    <Modal
      width="1085px"
      visible={modalVisible}
      style={{ height: '692px', position: 'relative' }}
      onCancel={() => toggleModal()}
      destroyOnClose
      footer={false}
      centered
    >
      <div ref={modalInnerRef} style={{ width: '100%', height: '100%' }}>
        <TransformWrapper
          ref={transformComponentRef}
          initialScale={scaleFactor}
          minScale={1}
          maxScale={7}
          limitToBounds={true}
          initialPositionX={0}
          initialPositionY={0}
          wheel={{ disabled: true }}
          disabled={isFullscreen || isMoveable}
          doubleClick={{
            mode: 'reset'
          }}
          panning={{ excluded: ['input', 'textarea'] }}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <ModalInner>
              <section
                className="section"
                style={{
                  paddingBottom: 0,
                  paddingLeft: 0,
                  background: 'white'
                }}
              >
                <HeadWrap isFullscreen={isFullscreen}>
                  <div className="course-preview__head-title">
                    <Icon
                      className="course-preview__head-title__icon"
                      type="read"
                    />
                    <div className="course-preview__head-title__text">
                      <h2>{course.title}</h2>
                      <p>
                        <h3>{currentLessonTitle}</h3>
                      </p>
                    </div>
                  </div>
                  {!lesson.fetch && (
                    <RenderZoomButtons
                      zoomIn={zoomIn}
                      zoomOut={zoomOut}
                      resetTransform={resetTransform}
                    />
                  )}
                </HeadWrap>
              </section>
              <TransformComponent
                wrapperStyle={{
                  width: '100%'
                }}
                contentStyle={{ width: `100%` }}
              >
                <LandscapePageWrapper ref={fullscreenRef}>
                  {lesson.fetch ? (
                    <LandscapePageContent isFullscreen={isFullscreen} loading>
                      <Spinner size={10} top={35} />
                    </LandscapePageContent>
                  ) : (
                    <LandscapePageContent
                      isFullscreen={isFullscreen}
                      ref={pageContentRef}
                    >
                      {lesson.item &&
                        lesson.item.pages_landscape &&
                        lesson.item.pages_landscape.length &&
                        lesson.item.pages_landscape[currentPage - 1] &&
                        lesson.item.pages_landscape[currentPage - 1].data &&
                        lesson.item.pages_landscape[currentPage - 1].data.map(
                          page => {
                            if (!page.params || !page.params.content) {
                              return null
                            }
                            return (
                              <Template
                                key={page.uid}
                                node={page}
                                ids={{
                                  courseId: course.id,
                                  lessonId: activeLessonId,
                                  pageId:
                                    lesson.item.pages_landscape[currentPage - 1]
                                      .id
                                }}
                                onDragStart={() => setIsMoveable(true)}
                                onDragStop={() => setIsMoveable(false)}
                                toggleQuizRestriction={
                                  setIsGoToNextPageDisabled
                                }
                                isFullscreen={isFullscreen}
                                scaleFactor={scaleFactor}
                              />
                            )
                          }
                        )}
                    </LandscapePageContent>
                  )}
                  {isFullscreen && <FooterElement />}
                </LandscapePageWrapper>
              </TransformComponent>
            </ModalInner>
          )}
        </TransformWrapper>
      </div>

      <FooterElement />
    </Modal>
  )
}

export default ModalLessonLandscape
