import React, { useState, useEffect } from 'react'
import { Modal, Button, Slider, Divider, Icon, Card } from 'antd'
import { ChromePicker } from 'react-color'
import ImagePreview from '../../../ImagePreview'

const BackgroundImageSettings = ({
  visible,
  onCancel,
  onRemove,
  onChangeImage,
  backgroundElement,
  handleParams,
  pageStructure
}) => {
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    if (backgroundElement && backgroundElement.params) {
      setOpacity(backgroundElement.params.opacity || 1)
    }
  }, [backgroundElement])

  const handleOpacityChange = value => {
    setOpacity(value)
  }

  const applyOpacity = () => {
    handleParams(backgroundElement.uid, {
      ...backgroundElement.params,
      opacity: opacity
    })

    onCancel()
  }

  return (
    <Modal
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon type="picture" style={{ marginRight: 8 }} />
          Background Image Settings
        </div>
      }
      visible={visible}
      onCancel={onCancel}
      width={520}
      footer={null}
      centered
      bodyStyle={{ padding: '24px' }}
    >
      {backgroundElement ? (
        <div>
          <div style={{ marginBottom: 24 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 16
              }}
            >
              <h4
                style={{
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.85)',
                  margin: 0
                }}
              >
                Current Image
              </h4>
              <Button
                type="primary"
                ghost
                onClick={onChangeImage}
                icon="picture"
                size="small"
              >
                Change Image
              </Button>
            </div>
            <div
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: 4,
                padding: 8,
                backgroundColor: '#fafafa'
              }}
            >
              <ImagePreview
                src={backgroundElement.params.content}
                style={{
                  width: '100%',
                  height: 200,
                  objectFit: 'cover',
                  borderRadius: 2,
                  opacity: opacity
                }}
              />
            </div>
          </div>

          <Card style={{ marginBottom: 24 }}>
            <h4
              style={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.85)',
                marginBottom: 16
              }}
            >
              Image Transparency
            </h4>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 16
              }}
            >
              <Slider
                min={0.1}
                max={1}
                step={0.1}
                value={opacity}
                onChange={handleOpacityChange}
                style={{ flex: 1 }}
              />
              <span
                style={{
                  minWidth: 40,
                  color: 'rgba(0, 0, 0, 0.65)'
                }}
              >
                {opacity.toFixed(1)}
              </span>
            </div>
          </Card>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 8,
              marginTop: 24
            }}
          >
            <Button
              onClick={onRemove}
              icon="delete"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              Remove Image
            </Button>
            <div style={{ display: 'flex', gap: 8 }}>
              <Button
                type="primary"
                onClick={applyOpacity}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Icon type="check" />
                Apply Changes
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            textAlign: 'center',
            padding: '32px 0'
          }}
        >
          <Icon
            type="picture"
            style={{
              fontSize: 48,
              color: '#d9d9d9',
              marginBottom: 16
            }}
          />
          <p
            style={{
              color: 'rgba(0, 0, 0, 0.45)',
              marginBottom: 24
            }}
          >
            No background image selected
          </p>
          <Button type="primary" onClick={onChangeImage} icon="upload">
            Add Image
          </Button>
        </div>
      )}
    </Modal>
  )
}

export default BackgroundImageSettings
