import { Icon } from 'antd'
import React from 'react'

import './StepTitle.scss'

function StepTitle({ iconType = '', title = '', description = '' }) {
  return (
    <div className="video-generation__step-title">
      <div className="video-generation__step-title__icon">
        <Icon type={iconType} />
      </div>

      <div className="video-generation__step-title__content">
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </div>
  )
}

export default StepTitle
